@mixin tablet {
  .button {
    font-size: 14px;
    height: 42px;
    white-space: nowrap;
  }

  .small {
    font-size: 14px;
    line-height: 22px;
    padding: 8px 20px;
    height: 38px;
  }
}
