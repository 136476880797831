@mixin tablet {
  .voyagerPass {
    padding: 24px;
    gap: 50px;
    justify-content: space-between;
  }

  .voyagerLeft {
    .nftName {
      font-size: 38px;
      line-height: 52px;
    }
    .vpDesc {
      font-size: 14px;
      line-height: 24px; /* 150% */
      margin-top: 20px;
    }
  }

  .voyagerRight {
    flex-shrink: 1;
    margin-right: 0;
  }
}
