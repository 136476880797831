@mixin mobile {
  .button {
    padding: 10px;
    min-width: 80px;
    white-space: normal;
    height: auto;
    min-height: 42px;
  }

  .small {
    font-size: 12px;
    line-height: 22px;
    padding: 6px 16px;
    border-radius: 8px;
    height: 34px;
    min-height: 34px;
    white-space: nowrap;
    flex-shrink: 0;
  }
}
