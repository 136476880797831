@import './index-tablet.module.scss';
@import './index-mobile.module.scss';

.page {
  margin: 0 auto;
  .main {
    margin: 0 auto;
  }
  .banner {
    margin: 24px;
  }
  .bannerInner {
    max-width: 1440px;
    margin: 24px auto;
    background-image: url('/img/bid/banner.png');
    background-size: cover;
    background-repeat: no-repeat;
    padding: 140px 70px;
    border-radius: 24px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
  }
  .bannerDesc {
    color: #fff;
    font-family: 'Red Hat Display';
    font-size: 70px;
    font-style: normal;
    font-weight: 800;
    line-height: 86px;
    letter-spacing: 1.4px;
  }
  .bannerDescContent {
    color: #fff;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    margin-top: 24px;
    width: 500px;
  }
  .bannerButton {
    margin-top: 24px;
    cursor: pointer;
    display: flex;
    padding: 10px 16px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    color: #232323;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    background: #fff;
    border-radius: 8px;
    border: 1px solid #dbdbdb;
    background: #fff;
    box-shadow: 0px 3px 6px -3px rgba(0, 0, 0, 0.05), 0px 2px 4px -2px rgba(0, 0, 0, 0.05),
      0px 1px 2px -1px rgba(0, 0, 0, 0.05), 0px 1px 0px -1px rgba(0, 0, 0, 0.05);
  }
  .title {
    color: #232323;
    font-size: 48px;
    font-style: normal;
    font-weight: 600;
    line-height: 56px;
  }
  .desc {
    color: #4f4f4f;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    margin-top: 24px;
  }

  .itemTitle {
    color: #232323;
    font-size: 24px;
    font-weight: 600;
    line-height: 32px;
    margin-top: 46px;
  }

  .itemUl {
    color: #4f4f4f;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    margin-top: 18px;
    list-style-type: disc;
    li {
      margin-left: 24px;
      line-height: 28px;
    }
  }

  .emailLink {
    color: #0354bf;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    text-decoration-line: underline;
  }

  .contactUS {
    padding: 4px 56px 56px 56px;
    gap: 18px;
    border-radius: 24px;
    background-image: url('/img/bid/20241016-154446.png');
    background-size: auto 100%;
    background-repeat: no-repeat;
    max-width: 1288px;
    width: 90%;
    margin: 64px auto;
  }

  .container {
    margin: 64px auto;
    max-width: 1288px;
    padding-left: 24px;
    padding-right: 24px;
  }

  .container2 {
    background: #fbfbfb;
  }

  .container2Inner {
    display: flex;
    max-width: 1288px;
    margin: 0 auto;
    padding: 14px 24px 64px;
    flex-direction: column;
    align-items: flex-start;
  }

  .sendButton {
    display: inline-flex;
    padding: 14px 20px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 8px;
    background: #0354bf;
    margin-top: 24px;
    color: #fff;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
  }
}

/* 小于1024px tablet */
@media screen and (max-width: 1024px) {
  @include tablet();
}
/* 小于480px mobile */
@media screen and (max-width: 480px) {
  @include mobile();
}
