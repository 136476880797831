@import './index-tablet.module.scss';
@import './index-mobile.module.scss';

.footerWrapper {
  display: flex;
  padding: 50px 90px;
  flex-direction: column;
  align-items: flex-start;
  gap: 30px;
  align-self: stretch;
  background: #333;
}

.footer {
  max-width: 1240px;
  width: 100%;
  margin: 0 auto;
  align-items: center;
  border-top: 1px solid #f1f2f2;
  color: #d1d1d1;
  .policy {
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    margin-top: 13px;
    user-select: none;
    white-space: nowrap;
    padding-bottom: 30px;
    margin-bottom: 30px;
    border-bottom: 1px solid #7a7a7a;
    a {
      margin-right: 20px;
      &:after {
        padding-left: 20px;
        content: '|';
      }
      &:last-child {
        margin-right: 0;
        &:after {
          content: '';
        }
      }
    }
  }

  .copyright {
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
  }

  .contact {
    display: flex;
    justify-content: space-between;
  }

  .email {
    margin-top: 30px;
    font-weight: 400;
    font-size: 14px;
    line-height: 28px;
    color: #d1d1d1;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .socialLink {
      display: flex;
      justify-content: space-between;
      .discordLogo,
      .twitterLogo,
      .facebookLogo {
        margin-left: 10px;
      }
    }
  }
}

/* 小于1024px tablet */
@media screen and (max-width: 1024px) {
  @include tablet();
}

@media screen and (max-width: 480px) {
  @include mobile();
}
