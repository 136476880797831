@mixin tablet {
  .right {
    margin-left: auto;
    .user,
    .language,
    .balance {
      margin-left: 10px;
      padding-left: 10px;
      padding-right: 10px;
    }
    .language {
      padding-left: 0;
      padding-right: 0;
    }
    .headerBtn {
      font-size: 12px;
    }
  }
  .headerWrap {
    width: 65%;
    z-index: 9;
    position: fixed;
    top: 0;
    right: 30px;
  }

  .header {
    width: 70%;
    z-index: 9;
    position: fixed;
    padding-left: 0;
    padding-right: 0;
    top: 0;
    right: 30px;
    .wallet {
      margin-left: 10px;
    }
    .left {
      display: none;
    }
  }
  .header2 {
    display: none;
  }
}
