@mixin tablet {
  .box {
    max-width: 500px;
    padding: 0 24px 6px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    overflow: hidden;
    min-width: auto;
    .img {
      width: 80px;
      height: 80px;
    }

    .text {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      width: 100%;
    }

    .title {
      text-align: left;
      font-weight: 500;
      font-size: 18px;
      line-height: 28px;
      color: #232323;
    }

    .btnBox {
      margin-top: 30px;
    }
  }

  .minWidthBox {
    min-width: 440px;
  }
}
