@mixin mobile {
  .redeemBgWrap {
    min-height: calc(100vh - 378px);
  }
  .redeemBg {
    padding: 24px 14px;
    flex-direction: column;
    gap: 16px;
    h1 {
      font-size: 32px;
      font-style: normal;
      font-weight: 600;
      line-height: 40px; /* 112.5% */
    }
    .tip {
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px; /* 157.143% */
    }

    .redeemLeft {
      max-width: 700px;
      width: 100%;
    }
  }
  .resultBox {
    width: 100%;
    align-items: flex-start;
  }

  .nftAddressBox {
    margin-top: 24px;
    padding: 7px 7px 7px 14px;
    svg {
      flex-shrink: 0;
    }
  }

  .contract {
    color: #a5a5a5;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px; /* 157.143% */
  }

  .dataRedeemed {
    .dataRedeemedRight {
      padding: 14px;
      font-size: 14px;
    }
  }
  .input {
    width: 120px;
  }
}
