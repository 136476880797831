@import './index-tablet.module.scss';
@import './index-mobile.module.scss';

.dialog {
  z-index: 88;
  :global {
    .MuiPaper-root {
      max-width: none;
      box-shadow: none;
      margin: 0 20px;
      padding: 26px 0;
      background-color: #fff;
      border-radius: 12px;
    }
  }
}

.innerBox {
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}

/* 小于1024px tablet */
@media screen and (max-width: 1024px) {
  @include tablet();
}
/* 小于480px mobile */
@media screen and (max-width: 480px) {
  @include mobile();
}
