@import './index-mobile.module.scss';
@import './index-tablet.module.scss';

.header {
  width: 100%;
  color: #232323;
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  line-height: 32px; /* 133.333% */
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.walletItemIcon {
  width: 32px;
  height: 32px;
}

.walletItem {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 20px;
  margin-top: 24px;
  border: 1px solid #dbdbdb;
  border-radius: 8px;
  padding: 20px;
  color: #232323;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  cursor: pointer;
}

/* 小于1024px tablet */
@media screen and (max-width: 1024px) {
  @include tablet();
}

/* 小于480px mobile */
@media screen and (max-width: 480px) {
  @include mobile();
}
