@mixin mobile {
  .dialog {
    z-index: 88;
    :global {
      .MuiPaper-root {
        max-width: none;
        box-shadow: none;
        margin: 0 20px;
        padding: 26px 0;
        background-color: #fff;
        border-radius: 12px;
      }
    }
  }
}
