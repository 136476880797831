@import './index-tablet.module.scss';
@import './index-mobile.module.scss';

.page {
  // padding: 0 60px;
  margin: 0 auto;

  // .huodongbannerWrap {
  //   display: flex;
  //   width: 100%;
  //   padding: 10px 100px;
  //   justify-content: center;
  //   align-items: center;
  //   gap: 40px;
  //   background: #ffc75e;
  //   color: #121212;
  //   font-size: 18px;
  //   font-weight: 700;
  //   letter-spacing: 0.36px;
  //   .huodongbanner {
  //     display: flex;
  //     max-width: 1440px;
  //     display: flex;
  //     justify-content: center;
  //     align-items: center;
  //     gap: 20px;
  //     > img {
  //       width: 48px;
  //     }
  //     .button {
  //       display: flex;
  //       padding: 8px 20px;
  //       justify-content: center;
  //       align-items: center;
  //       gap: 8px;

  //       border-radius: 8px;
  //       background: #f60;
  //       cursor: pointer;
  //       white-space: nowrap;
  //       color: #fff;
  //       font-size: 14px;
  //       font-weight: 600;
  //       line-height: 20px; /* 142.857% */
  //     }
  //   }
  // }
  .maxWidth1440 {
    max-width: 1440px;
    margin: 0 auto;
  }

  .bannerWrap {
    // 活动
    // margin-top: 70px;
    //  margin-top: 10px;
    position: relative;
    background: #f0f5fb;

    .swiperBanner {
      max-width: 1440px;
      margin: 0 auto;
    }

    .banner {
      padding: 20px 100px 50px;
      max-width: 1440px;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      .program {
        font-weight: 300;
        font-size: 16px;
        line-height: 24px;
        color: #4f4f4f;
        display: flex;
        align-items: center;
        justify-content: space-between;
        text-transform: none;
        width: 100%;
        gap: 20px;
        .dashes {
          width: 100%;
          height: 1px;
          background-color: #4f4f4f;
          opacity: 0.5;
        }
        .desc {
          white-space: nowrap;
        }
      }

      .web3Id {
        width: auto;
        display: inline-block;
        align-items: flex-start;
        flex-wrap: wrap;
        .legal {
          font-weight: 800;
          color: #232323;
          font-size: 80px;
          line-height: 100px;
          white-space: nowrap;
        }
      }

      .benefits {
        display: flex;
        flex-direction: column;
        align-content: stretch;
        margin-top: 20px;
        .benefitItem {
          color: #232323;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px;
          gap: 10px;
          display: flex;
          margin-bottom: 10px;
          align-items: center;
          white-space: nowrap;
          svg {
            flex-shrink: 0;
          }
          img {
            width: 20px;
            height: 20px;
          }
        }
      }

      .btnGroup {
        display: flex;
        gap: 32px;
        margin-top: 30px;
        .nftBtn {
          color: #0355bf;
          border-color: #0355bf;
          white-space: normal;
          min-height: 52px;
          height: auto;
        }
      }
      .linkGroup {
        display: flex;
        gap: 58px;
        margin-top: 20px;
        white-space: nowrap;
        a {
          display: flex;
          gap: 16px;
          align-items: center;
          text-decoration: underline;
          svg {
            flex-shrink: 0;
          }
        }
      }
    }

    .bannerLeft {
      max-width: 50%;
    }

    .banner1 {
      background: #f0f5fb;
      background-image: url('/img/index/banner1.png?v=2');
      background-repeat: no-repeat;
      background-size: auto 100%;
      padding-top: 50px;
    }
    .banner2 {
      background: #f0f5fb;
      background-image: url('/img/index/banner2.png?v=2');
      background-repeat: no-repeat;
      background-size: auto 100%;
      padding-top: 40px;
      .program {
        margin-top: 20px;
        .desc {
          white-space: normal;
        }
      }
    }
  }

  .bannerDesc {
    max-width: 1200px;
    padding: 40px 40px;
    width: calc(100% - 120px);
    gap: 18px;
    margin: 0 auto;
    margin-top: -100px;
    margin-bottom: 100px;
    background: rgba(255, 255, 255, 0.6);
    box-shadow: 0px 14px 42px rgba(126, 86, 86, 0.04);
    backdrop-filter: blur(10px);
    border-radius: 36px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .bannerDescTitle {
      font-weight: 700;
      font-size: 34px;
      line-height: 52px;
      color: #232323;
    }
    .bannerDescContent {
      font-weight: 400;
      font-size: 16px;
      line-height: 28px;
      text-align: center;
      color: #717c7a;
    }
  }

  .indexCustomBtn {
    padding-right: 20px;
    padding-left: 20px;
    min-width: 228px;
    display: flex;
    gap: 16px;
  }

  .vp {
    max-width: 1200px;
    margin: 140px auto 0;
    display: flex;
    gap: 60px;
    justify-content: space-around;
    .vpContent {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding-right: 20px;
      margin-top: 12px;
      margin-bottom: 12px;
    }
    .vpVideo {
      background-color: #000;
      border-radius: 36px;
      width: 500px;
      height: 566px;
    }
    .vpType {
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0.04em;
      text-transform: uppercase;
      color: #0355bf;
    }
    .vpTitle {
      font-weight: 700;
      font-size: 42px;
      line-height: 52px;
      color: #232323;
      margin-top: 12px;
    }
    .vpDesc {
      font-weight: 400;
      font-size: 16px;
      line-height: 28px;
      color: #717c7a;
      margin-top: 20px;
    }
    .vpBenefit {
      margin-top: 20px;
    }
    .vpBenefitItem {
      font-weight: 400;
      font-size: 16px;
      line-height: 28px;
      color: #232323;
      display: flex;
      gap: 10px;
      align-items: center;
      margin-bottom: 10px;
    }
    .vpBtn {
      margin-top: auto;
    }
  }

  .rnsPointsWrap {
    margin-left: -60px;
    margin-right: -60px;
    width: calc(100% + 120px);
    background: #fafafa;
    position: relative;
  }

  .rnsPoints {
    max-width: 1200px;
    margin: 100px auto 0;
    padding-top: 80px;
    padding-bottom: 70px;
    display: flex;
    gap: 100px;
    justify-content: space-around;
    .discountImage {
      width: 500px;
    }
    .rnsPointsContent {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      margin-top: 38px;
      padding-right: 20px;
    }
    .rnsPointsTip {
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      display: flex;
      align-items: center;
      letter-spacing: 0.04em;
      text-transform: uppercase;
      color: #0355bf;
    }
    .rnsPointsTitle {
      font-weight: 700;
      font-size: 42px;
      line-height: 52px;
      margin-top: 12px;
      display: flex;
      align-items: center;

      color: #232323;
    }
    .rnsPointsDesc {
      font-weight: 400;
      font-size: 16px;
      line-height: 28px;
      color: #717c7a;
      margin-top: 26px;
    }
    .rnsPointsBtn {
      margin-top: auto;
      margin-bottom: 40px;
    }
  }

  .launch {
    max-width: 1200px;
    margin: 0 auto;
    padding: 100px 0;
    display: flex;
    gap: 40px;
    justify-content: space-between;
    .launchLeft {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      margin-top: 20px;
      margin-bottom: 20px;
    }
    .launchRight {
      width: 630px;
      padding: 50px;
      background: #ffffff;
      border: 1px solid #f1f2f2;
      box-shadow: 0px 14px 42px rgba(126, 86, 86, 0.04);
      border-radius: 36px;
      .recommend {
        width: 100%;
        gap: 40px;
        margin-bottom: 50px;
        .recommendHeader {
          display: flex;
          gap: 26px;
          align-items: center;
        }
        .recommendImage {
          width: 70px;
          height: 70px;
          border-radius: 16px;
        }
        .recommendName {
          font-weight: 600;
          font-size: 18px;
          line-height: 28px;
          color: #232323;
        }
        .recommendPosition {
          font-weight: 400;
          font-size: 18px;
          line-height: 28px;
          color: #717c7a;
        }
        .recommendWord {
          margin-top: 46px;
          font-weight: 600;
          font-size: 18px;
          line-height: 32px;
          color: #232323;
        }
      }
      .customSwiper {
        :global {
          .swiper-pagination-horizontal {
            bottom: 0;
          }
          .swiper-pagination-bullet-active {
            background-color: #0355bf;
          }
        }
      }
    }
    .launchTitle {
      font-weight: 700;
      font-size: 42px;
      line-height: 52px;
      color: #232323;
    }
    .launchDesc {
      font-weight: 400;
      font-size: 16px;
      line-height: 28px;
      color: #717c7a;
      margin-top: 12px;
      max-width: 400px;
    }
    .launchLink {
      font-weight: 500;
      font-size: 18px;
      line-height: 28px;
      color: #0355bf;
      margin-top: auto;
      display: flex;
      gap: 10px;
      align-items: center;
      white-space: nowrap;
      svg {
        flex-shrink: 0;
      }
    }
  }

  .saidWrap {
    margin-left: -60px;
    margin-right: -60px;
    width: calc(100% + 120px);
    background: #fafafa;
    position: relative;
  }
  .said {
    padding-top: 94px;
    padding-bottom: 100px;
    .saidTitle {
      font-weight: 700;
      font-size: 42px;
      line-height: 52px;
      color: #232323;
      text-align: center;
    }
    .saidContent {
      display: flex;
      gap: 60px;
      margin-top: 50px;
      justify-content: center;
      padding: 0 20px;
    }
    .saidItem {
      padding: 50px;
      gap: 40px;
      max-width: 1180px;
      background: #ffffff;
      border: 1px solid #f1f2f2;
      box-shadow: 0px 14px 42px rgba(126, 86, 86, 0.04);
      border-radius: 36px;
      position: relative;
      .saidItemHeader {
        display: flex;
        gap: 26px;
        align-items: center;
      }
      .saidItemImage {
        width: 70px;
        height: 70px;
        border-radius: 16px;
      }
      .saidItemPosition {
        font-weight: 400;
        font-size: 18px;
        line-height: 28px;
        color: #717c7a;
      }
      .saidItemWord {
        margin-top: 46px;
        font-weight: 600;
        font-size: 18px;
        line-height: 32px;
        color: #232323;
        padding-bottom: 50px;
        a {
          text-decoration: underline;
        }
      }
      .saidItemLink {
        font-weight: 500;
        font-size: 18px;
        line-height: 28px;
        color: #0355bf;
        display: flex;
        gap: 10px;
        align-items: center;
        position: absolute;
        bottom: 50px;
      }
    }
  }

  .protocol {
    padding-top: 100px;
    padding-bottom: 100px;
    .protocolTip {
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      text-align: center;
      letter-spacing: 0.04em;
      text-transform: uppercase;
      color: #0355bf;
      text-align: center;
    }
    .protocolTitle {
      font-weight: 700;
      font-size: 42px;
      line-height: 52px;
      color: #232323;
      text-align: center;
      margin-top: 12px;
    }
    .protocolDesc {
      font-weight: 400;
      font-size: 16px;
      line-height: 28px;
      text-align: center;
      color: #717c7a;
      max-width: 1000px;
      margin: 24px auto 0;
    }
    .protocolContent {
      margin: 60px auto 0;
      display: flex;
      justify-content: center;
      gap: 60px;
    }
    .protocolItem {
      padding: 30px;
      width: 340px;
      background: #ffffff;
      border: 1px solid #f1f2f2;
      box-shadow: 0px 14px 42px rgba(126, 86, 86, 0.04);
      border-radius: 36px;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      gap: 30px;
    }
    .protocolImage {
      width: 72px;
      height: 60px;
    }
    .protocolText {
      font-weight: 600;
      font-size: 24px;
      line-height: 36px;
      color: #232323;
    }
  }

  .kycTitle,
  .verificationTitle {
    font-weight: 700;
    font-size: 42px;
    line-height: 52px;
    display: flex;
    align-items: center;
    margin: 0 auto 60px;
    padding-top: 80px;
    text-align: center;
    justify-content: center;
    color: #232323;
  }

  .kycList,
  .verificationList {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 20px;
    padding-bottom: 100px;
    .verificationItem,
    .kycItem {
      width: 224px;
    }
  }

  .verificationList {
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    margin-left: 60px;
    margin-right: 60px;
  }

  .verificationListsWrap {
    margin-left: -60px;
    margin-right: -60px;
    width: calc(100% + 120px);
    background: #fafafa;
    position: relative;
  }
}

.japanOuterDialog {
  :global {
    .MuiDialog-paper {
      padding: 0;
      border-radius: 14px;
      border: 1px solid #ffbcd8;
    }
  }
}
.japanDialog {
  width: 700px;
  height: auto;
  padding: 20px;
  gap: 10px;
  background: #ffffff;
  border-radius: 12px;
  position: relative;
}

.japanInnerDialog {
  padding: 46px 70px 50px;
  width: 100%;
  height: 100%;
  border-radius: 12px;
  position: relative;
  text-align: center;
  .img {
    width: 120px;
    height: 120px;
    margin: 0 auto;
  }
  .text {
    margin-top: 30px;
    font-weight: 500;
    font-size: 22px;
    line-height: 34px;
    text-align: center;
    color: #d7538e;
  }
  .btn {
    cursor: pointer;
    margin: 40px auto 0;
    padding: 14px 30px;
    gap: 10px;
    display: inline-block;
    height: 52px;
    background: #d7538e;
    border-radius: 8px;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #ffffff;
  }
  .close {
    position: absolute;
    right: 0;
    top: 0;
    width: 24px;
    height: 24px;
    cursor: pointer;
  }
}

.estoniaImg {
  width: 251px;
  height: 122px;
  margin: 0 auto;
}

.estoniaTitle {
  font-weight: 500;
  font-size: 22px;
  line-height: 34px;
  color: #232323;
  text-align: center;
}

.estoniaText1 {
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  text-align: center;
  color: #7a7a7a;
  margin-top: 10px;
}

.estoniaText2 {
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  text-align: center;
  color: #7a7a7a;
  margin-top: 20px;
}

.cookiePolicyPaddingBottom {
  padding-bottom: 200px;
}

.lawTitle {
  color: #232323;
  font-size: 22px;
  font-weight: 500;
  line-height: 34px;
}

.lawTips {
  list-style: disc;
  color: #7a7a7a;
  font-size: 16px;
  line-height: 26px;
  padding-left: 20px;
  padding-right: 20px;
  margin-top: 20px;
}

.Frame1071 {
  max-width: 1440px;
  margin: 0 auto;
  padding: 60px 100px 44px;

  .Frame867 {
    display: flex;
    width: 100%;
    justify-content: space-between;
    gap: 20px;
  }

  .Frame8671 {
    color: #232323;
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: 44px;
  }

  .Frame1070 {
    display: flex;
    gap: 20px;
    margin-top: 44px;
  }

  .Frame10701 {
    display: flex;
    gap: 20px;
    width: 100%;
  }

  .Frame10702 {
    width: 100%;
    .Frame107022 {
      color: #232323;
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: 30px;
      margin-top: 20px;
    }

    .Frame107023 {
      color: #7a7a7a;
      font-size: 15px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px; /* 146.667% */
      margin-top: 12px;
    }

    .Frame107024 {
      color: #0355bf;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
      margin-top: 26px;
      display: flex;
      align-items: center;
      gap: 10px;
    }
  }

  .Frame10661 {
    color: #232323;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  .Frame10662 {
    color: #7a7a7a;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    margin-top: 12px;
  }

  .Rectangle8528 {
    width: 222px;
    height: 200px;
    flex-shrink: 0;
    background-color: #e2e2e2;
    background-size: cover;
    background-position: center;
    border-radius: 14px;
    background-repeat: no-repeat;
  }

  .Frame1068 {
    display: flex;
    flex-direction: column;
  }

  .Frame836 {
    display: flex;
    margin-top: auto;
    align-self: flex-start;
    width: 100%;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  .Frame827 {
    display: flex;
    gap: 6px;
    align-items: center;
    white-space: nowrap;
  }

  .Frame8271 {
    color: #0355bf;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
  }

  .MaskGroup,
  .MaskGroup1,
  .MaskGroup2 {
    margin-top: 56px;
    :global {
      .swiper {
        padding-bottom: 30px;
      }
      .swiper-pagination-horizontal {
        bottom: 0px;
      }
    }
  }

  .MaskGroup1,
  .MaskGroup2 {
    display: none;
  }

  .Frame1100 {
    display: flex;
    gap: 30px;
  }

  .Frame1066 {
    display: flex;
    width: 370px;
    padding: 26px;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;
    border-radius: 14px;
    background: #fafafa;
    height: 220px;
  }

  .Frame10981 {
    color: #7a7a7a;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
  }

  .Frame1098 {
    display: flex;
    gap: 14px;
    align-items: center;
    .Image143 {
      width: 36px;
      height: 36px;
      padding: 8px;
      border-radius: 50%;
      background-color: #f1f2f2;
    }
    .ELM {
      color: #232323;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 22px;
    }
    .ELM13 {
      color: #7a7a7a;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 18px;
    }
  }
}

.Frame1072 {
  padding-top: 40px;
}

.mediaList {
  max-width: 1440px;
  padding-left: 100px;
  padding-right: 100px;
  height: 60px;
  margin: 8px auto 44px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  overflow: hidden;
  .mediaPic {
    max-width: 13%;
    height: auto;
    flex: 1;
  }
}

.mobileCard {
  display: none;
}

.xMLParser {
  /* fonts */
  --font-bakbak-one: 'Bakbak One';

  /* font sizes */
  --sm-regular-size: 14px;
  --lg-medium-size: 18px;
  --md-regular-size: 14px;
  --xl-semibold-size: 26px;
  --xl-semibold3-size: 20px;
  --xl-semibold1-size: 48px;
  --xl-semibold2-size: 32px;
  --font-size-3xl: 22px;
  --font-size-lgi: 19px;
  --xs-semibold-size: 12px;
  --font-size-81xl: 100px;
  --font-size-31xl: 50px;

  /* Colors */
  --white: #fff;
  --gray-800: #383838;
  --gray-300: #dbdbdb;
  --gray-50: #fbfbfb;
  --gray-500: #7c7c7c;
  --black: #232323;
  --color-gray-100: rgba(255, 255, 255, 0.5);
  --color-gray-200: rgba(0, 0, 0, 0.16);
  --gray-100: #f5f5f5;
  --gray-200: #ececec;
  --primary-600: #024399;
  --color-paleturquoise: #bfe9dd;
  --primary-500: #0354bf;
  --gray-400: #a5a5a5;
  --primary-50: #f2f6fc;
  --gray-700: #4f4f4f;

  /* Gaps */
  --gap-12xl-8: 31.8px;
  --gap-base: 16px;
  --gap-9xs: 4px;
  --gap-sm: 14px;
  --gap-3xs: 10px;
  --gap-xs: 12px;
  --gap-29xl: 48px;
  --gap-5xl: 24px;
  --gap-13xl: 32px;
  --gap-xl: 20px;
  --gap-5xs: 8px;
  --gap-53xl: 72px;
  --gap-17xl: 36px;
  --gap-lg: 18px;
  --gap-21xl: 30px;
  --gap-529xl: 548px;
  --gap-255xl: 274px;
  --gap-118xl: 137px;
  --gap-49xl: 68px;

  /* Paddings */
  --padding-37xl: 56px;
  --padding-81xl: 100px;
  --padding-31xl: 50px;
  --padding-xl: 20px;
  --padding-10xs: 3px;
  --padding-5xs: 8px;
  --padding-54xl: 73px;
  --padding-8xs: 5px;
  --padding-19xl: 38px;
  --padding-21xl: 40px;
  --padding-109xl: 128px;
  --padding-35xl: 54px;
  --padding-64xl: 83px;
  --padding-43xl: 50px;
  --padding-44xl: 50px;
  --padding-12xl: 31px;
  --padding-9xs: 4px;
  --padding-234xl: 253px;
  --padding-107xl: 126px;
  --padding-sm: 14px;
  --padding-base: 16px;
  --padding-13xl: 32px;
  --padding-20xl: 39px;
  --padding-xs: 12px;
  --padding-6xl: 25px;
  --padding-123xl: 142px;
  --padding-52xl: 71px;
  --padding-128xl: 147px;
  --padding-136xl: 155px;
  --padding-58xl: 77px;
  --padding-62xl: 81px;
  --padding-77xl: 96px;
  --padding-69xl: 88px;
  --padding-99xl: 118px;
  --padding-5xl: 24px;
  --padding-4xl: 23px;
  --padding-11xs: 2px;
  --padding-11xl: 30px;
  --padding-3xs: 10px;
  --padding-mini: 15px;

  /* Border radiuses */
  --br-xl: 20px;
  --br-5xl: 24px;
  --br-9xs: 4px;
  --br-xs: 12px;
  --br-base: 16px;
  --br-5xs: 8px;

  /* Effects */
  --shadow-lg: 0px 6px 12px -6px rgba(0, 0, 0, 0.08), 0px 3px 6px -3px rgba(0, 0, 0, 0.08);
  --shadow-2xl: 0px 16px 32px -16px rgba(0, 0, 0, 0.08), 0px 12px 24px -12px rgba(0, 0, 0, 0.12);
  --shadow-sm: 0px 3px 6px -3px rgba(0, 0, 0, 0.05), 0px 2px 4px -2px rgba(0, 0, 0, 0.05),
    0px 1px 2px -1px rgba(0, 0, 0, 0.05), 0px 1px 0px -1px rgba(0, 0, 0, 0.05);
  .voyagerPass {
    margin: 0;
    align-self: stretch;
    position: relative;
    font-size: inherit;
    line-height: 32px;
    font-size: 32px;
    font-weight: 600;
    font-family: inherit;
  }
  .image152Icon {
    position: absolute;
    top: calc(50% - 18px);
    left: calc(50% - 16px);
    width: 32px;
    height: 36px;
    object-fit: contain;
  }
  .tableBuilder {
    cursor: pointer;
    border: 0;
    padding: 0;
    background-color: transparent;
    width: 64px;
    height: 64px;
    position: relative;
    border-radius: var(--br-5xl);
    background: linear-gradient(104.56deg, #e7f5f1, #e5f1ff);
    overflow: hidden;
    flex-shrink: 0;
  }
  .enhancedRnsRewards {
    margin: 0;
    align-self: stretch;
    position: relative;
    font-size: inherit;
    line-height: 32px;
    font-weight: 600;
    font-family: inherit;
  }
  .receive150Bonus {
    align-self: stretch;
    position: relative;
    font-size: var(--md-regular-size);
    line-height: 20px;
    color: var(--gray-500);
  }
  .chartCreator,
  .diagramMaker {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .diagramMaker {
    align-self: stretch;
    gap: var(--gap-5xs);
  }
  .chartCreator {
    flex: 1;
    box-shadow: var(--shadow-lg);
    border-radius: var(--br-5xl);
    background-color: var(--white);
    border: 1px solid var(--gray-200);
    box-sizing: border-box;
    overflow: hidden;
    padding: var(--padding-19xl) var(--padding-20xl);
    gap: var(--gap-xl);
    min-width: 294px;
    max-width: 100%;
  }
  .image153Icon {
    position: absolute;
    top: calc(50% - 20px);
    left: calc(50% - 12px);
    width: 24px;
    height: 40px;
    object-fit: contain;
  }
  .image153Wrapper {
    cursor: pointer;
    border: 0;
    padding: 0;
    background-color: transparent;
    width: 64px;
    height: 64px;
    position: relative;
    border-radius: var(--br-5xl);
    background: linear-gradient(104.56deg, #e7f5f1, #e5f1ff);
    overflow: hidden;
    flex-shrink: 0;
  }
  .priorityAccess {
    margin: 0;
    position: relative;
    font-size: inherit;
    line-height: 32px;
    font-weight: 600;
    font-family: inherit;
  }
  .benefitFromExpedited {
    align-self: stretch;
    position: relative;
    font-size: var(--md-regular-size);
    line-height: 20px;
    color: var(--gray-500);
  }
  .chartCreator1,
  .priorityAccessFeaturesParent {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .priorityAccessFeaturesParent {
    align-self: stretch;
    gap: var(--gap-5xs);
  }
  .chartCreator1 {
    flex: 1;
    box-shadow: var(--shadow-lg);
    border-radius: var(--br-5xl);
    background-color: var(--white);
    border: 1px solid var(--gray-200);
    box-sizing: border-box;
    overflow: hidden;
    padding: var(--padding-19xl) var(--padding-20xl);
    gap: var(--gap-xl);
    min-width: 294px;
    max-width: 100%;
  }
  .image150Icon {
    position: absolute;
    top: calc(50% - 17px);
    left: calc(50% - 20px);
    width: 40px;
    height: 34px;
    object-fit: contain;
  }
  .image150Wrapper {
    cursor: pointer;
    border: 0;
    padding: 0;
    background-color: transparent;
    width: 64px;
    height: 64px;
    position: relative;
    border-radius: var(--br-5xl);
    background: linear-gradient(104.56deg, #e7f5f1, #e5f1ff);
    overflow: hidden;
    flex-shrink: 0;
  }
  .exclusiveTravelSavings {
    margin: 0;
    align-self: stretch;
    position: relative;
    font-size: inherit;
    line-height: 32px;
    font-weight: 600;
    font-family: inherit;
  }
  .unlockSignificantDiscounts {
    align-self: stretch;
    position: relative;
    font-size: var(--md-regular-size);
    line-height: 20px;
    color: var(--gray-500);
  }
  .chartCreator2,
  .exclusiveTravelSavingsParent,
  .tabulator {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .exclusiveTravelSavingsParent {
    align-self: stretch;
    flex-direction: column;
    gap: var(--gap-5xs);
  }
  .chartCreator2,
  .tabulator {
    max-width: 100%;
  }
  .chartCreator2 {
    flex: 1;
    box-shadow: var(--shadow-lg);
    border-radius: var(--br-5xl);
    background-color: var(--white);
    border: 1px solid var(--gray-200);
    box-sizing: border-box;
    overflow: hidden;
    flex-direction: column;
    padding: var(--padding-19xl) var(--padding-20xl);
    gap: var(--gap-xl);
    min-width: 294px;
  }
  .tabulator {
    align-self: stretch;
    flex-direction: row;
    flex-wrap: wrap;
    gap: var(--gap-13xl);
    font-size: var(--xl-semibold3-size);
  }
  .readyToSeize {
    margin: 0;
    align-self: stretch;
    position: relative;
    font-size: inherit;
    line-height: 40px;
    font-weight: 600;
    font-family: inherit;
  }
  .addIcon {
    height: 24px;
    width: 24px;
    position: relative;
    overflow: hidden;
    flex-shrink: 0;
    display: none;
  }
  .button1 {
    position: relative;
    font-size: var(--lg-medium-size);
    line-height: 28px;
    font-weight: 500;

    color: var(--white);
    text-align: left;
  }
  .addIcon1 {
    height: 24px;
    width: 24px;
    position: relative;
    overflow: hidden;
    flex-shrink: 0;
    display: none;
  }
  .button {
    cursor: pointer;
    border: 0;
    padding: var(--padding-base) var(--padding-13xl);
    background-color: var(--primary-500);
    border-radius: var(--br-xs);
    overflow: hidden;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: var(--gap-base);
  }
  .addIcon2 {
    height: 24px;
    width: 24px;
    position: relative;
    overflow: hidden;
    flex-shrink: 0;
    display: none;
  }
  .button3 {
    position: relative;
    font-size: var(--lg-medium-size);
    line-height: 28px;
    font-weight: 500;
    color: var(--black);
    text-align: left;
  }
  .addIcon3 {
    height: 24px;
    width: 24px;
    position: relative;
    overflow: hidden;
    flex-shrink: 0;
    display: none;
  }
  .button2,
  .formBuilder {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .button2 {
    cursor: pointer;
    border: 1px solid var(--gray-300);
    padding: var(--padding-sm) var(--padding-12xl);
    background-color: var(--white);
    box-shadow: var(--shadow-sm);
    border-radius: var(--br-xs);
    overflow: hidden;
    justify-content: center;
    gap: var(--gap-base);
  }
  .formBuilder {
    align-self: stretch;
    justify-content: flex-start;
    gap: 20px;
  }
  .developerGuideIcon {
    height: 24px;
    width: 24px;
    position: relative;
    overflow: hidden;
    flex-shrink: 0;
  }
  .button4 {
    position: relative;
    line-height: 28px;
    font-weight: 500;
  }
  .developerGuideIcon1 {
    height: 24px;
    width: 24px;
    position: relative;
    overflow: hidden;
    flex-shrink: 0;
    display: none;
  }
  .selectField,
  .textLink {
    display: flex;
    flex-direction: row;
  }
  .textLink {
    border-radius: var(--br-9xs);
    overflow: hidden;
    align-items: center;
    justify-content: center;
    gap: var(--gap-5xs);
  }
  .selectField {
    align-self: stretch;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .labelMaker,
  .readyToSeizeTheDigitalAdvParent {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
  }
  .labelMaker {
    align-self: stretch;
    gap: var(--gap-5xl);
    font-size: var(--lg-medium-size);
    color: var(--primary-600);
  }
  .readyToSeizeTheDigitalAdvParent {
    flex: 1;
    padding: var(--padding-9xs) 0;
    box-sizing: border-box;
    gap: var(--gap-21xl);
    max-width: 100%;
    z-index: 1;
  }
  .image131Icon {
    width: 100%;
    height: 100%;
    object-fit: contain;
    position: absolute;
    left: 0;
    top: 20px;
    transform: scale(2.043);
  }
  .wrapperImage131 {
    position: absolute;
    top: calc(50% - 77px);
    left: calc(50% - 57px);
    width: 115px;
    height: 154px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .dataAggregator,
  .sliderController {
    border-radius: var(--br-5xl);
    box-sizing: border-box;
    overflow: hidden;
    position: relative;
  }
  .dataAggregator {
    height: 200px;
    width: 300px;
    box-shadow: var(--shadow-2xl);
    background: linear-gradient(180deg, #c2c3c7, #808089);
    border: 6px solid var(--white);
    flex-shrink: 0;
    min-width: 300px;
    z-index: 1;
    overflow: hidden;
  }
  .sliderController {
    align-self: stretch;
    box-shadow: var(--shadow-lg);
    background: linear-gradient(104.56deg, #e7f5f1, #e5f1ff);
    border: 1px solid var(--color-paleturquoise);
    flex-direction: row;
    justify-content: flex-start;
    padding: var(--padding-43xl) var(--padding-44xl);
    gap: var(--gap-53xl);
    font-size: var(--xl-semibold2-size);
    align-items: center;
  }
  .jSONParser,
  .sliderController,
  .xMLParser {
    display: flex;
    align-items: center;
    max-width: 100%;
  }
  .jSONParser {
    margin: 0 auto;
    width: 1240px;
    flex-direction: column;
    justify-content: flex-start;
    gap: var(--gap-29xl);
  }

  align-self: stretch;
  flex-direction: row;
  justify-content: center;
  margin-top: 60px;
  margin-bottom: 60px;
  box-sizing: border-box;
  text-align: left;
  font-size: var(--xl-semibold1-size);
  color: var(--black);
}

// 活动
// .activityBanner {
//   position: fixed;
//   display: flex;
//   z-index: 2;
//   width: 100%;
//   top: 0;
//   padding: 9px 100px;
//   justify-content: space-between;
//   align-items: center;
//   flex-shrink: 0;
//   background: linear-gradient(0deg, rgba(255, 198, 50, 0.42) 0%, rgba(255, 198, 50, 0.42) 100%), #fff;
//   .innerActivityBanner {
//     max-width: 1440px;
//     display: flex;
//     align-items: center;
//     justify-content: flex-start;
//     gap: 20px;
//     margin: 0 auto;
//     > button {
//       margin-left: 60px;
//     }
//     svg {
//       flex-shrink: 0;
//     }
//   }
// }

// 活动
// .indexWrapHeader2 {
//   top: 70px;
// }

/* 小于1024px tablet */
@media screen and (max-width: 1024px) {
  @include tablet();
}
/* 小于480px mobile */
@media screen and (max-width: 480px) {
  @include mobile();
}
