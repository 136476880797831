@import './index-tablet.module.scss';

.right {
  width: 20px;
  height: 20px;
}

/* 小于1024px tablet */
@media screen and (max-width: 1024px) {
  @include tablet();
}
