.list{
    display: flex;
    align-content:flex-start;
    flex-wrap: wrap;
    width: 100%;
    height: 100vh;
}

.icon{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 150px;
    height: 150px;
    border: 1px dashed #333;
    border-right: 0;
    position: relative;
    margin-bottom: -1px;
    &:last-child{
        border-right:1px dashed #333;
    }
    cursor: pointer;
}

.name{
    position: absolute;
    bottom: 4px;
    color: #333;
}