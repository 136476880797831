@mixin mobile {
  .termsDialog {
    padding-left: 0;
    padding-right: 0;
    margin-bottom: 16px;
    font-weight: 400;
    font-size: 12px;
    line-height: 22px;
    color: #41514e;
    ul {
      margin-left: 20px;
    }
    li {
      list-style-type: disc;
      margin-bottom: 10px;
      list-style-type: disc;
      margin-bottom: 10px;
      font-weight: 400;
      font-size: 12px;
      line-height: 22px;
      color: #41514e;
    }
  }
}
