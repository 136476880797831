.language {
  display: flex;
  align-items: center;
  height: 100%;
  background: #fafafa;
  border-radius: 8px;
  padding: 6px 0;
  font-weight: 600;
  :global {
    .MuiInput-underline:before .MuiInput-underline:after {
      display: none !important;
    }
  }

  .selectedValue {
    display: flex;
    align-items: center;
    padding: 0 12px;
    font-size: 14px !important;
    cursor: pointer;
    .global {
      margin-right: 6px;
    }
    gap: 6px;
  }
}

.select {
  font-size: 14px !important;
  background: transparent;
  height: 100%;
  :global {
    .MuiSelect-select {
      display: flex;
      align-items: center;
      box-sizing: border-box;
      height: 100%;
      background: transparent !important;
    }
  }
  &::before,
  &::after {
    display: none;
  }
}

.menu {
  margin-top: 10px;
}

.menu-item {
  font-size: 14px !important;
  padding-right: 20px;
  padding-left: 20px;
  display: flex;
  align-items: center;
  gap: 30px;
}
