@mixin mobile {
  .cookiePolicy {
    width: calc(100% - 40px);
    padding: 20px;
    .title {
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      color: #232323;
    }

    .subTitle {
      font-weight: 400;
      font-size: 16px;
      line-height: 26px;
      color: #232323;
      margin-top: 0px;
    }

    .content {
      font-weight: 400;
      font-size: 12px;
      line-height: 18px;
      color: #4f4f4f;
      margin-top: 12px;
    }

    .btnGroup {
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      gap: 10px;
      margin-top: 12px;
      > button {
        width: 100%;
      }
      > button:first-child {
        color: #0355bf;
        border-color: #0355bf;
      }
    }
    .customizeBtn {
      margin-top: 10px;
      width: 100%;
    }
  }
  .cookieSettings {
    .desc {
      font-weight: 400;
      font-size: 12px;
      line-height: 18px;
      color: #4f4f4f;
    }
    .cookieList {
      .cookieItem {
        padding: 4px 10px;
        display: flex;
        gap: 8px;
        box-shadow: inset 0px -1px 0px #f1f2f2;
        align-items: flex-start;
        &:last-child {
          box-shadow: none;
        }
        .left {
          flex-shrink: 0;
          margin-top: 8px;
          cursor: pointer;
        }
        .right {
          width: 100%;
        }
        .line1 {
          display: flex;
          align-items: center;
          justify-content: space-between;
          font-weight: 500;
          font-size: 12px;
          line-height: 18px;
          color: #232323;
          cursor: pointer;
        }
        .line2 {
          font-weight: 400;
          font-size: 12px;
          line-height: 18px;
          color: #7a7a7a;
          margin-bottom: 6px;
        }
      }
    }
  }
}
