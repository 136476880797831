@import './index-tablet.module.scss';
@import './index-mobile.module.scss';

.sidebar {
  display: none;
  position: fixed;
  white-space: nowrap;
  width: 280px;
  height: 100%;
  left: 0;
  right: auto;
  top: 0;
  flex: 1 0 auto;
  outline: 0;
  z-index: 8;
  overflow-y: auto;
  flex-direction: column;
  background: #fafafa;
  box-shadow: inset -2px 0px 0px #f6f6f6;
  margin-right: 1000px;
}

.beta {
  position: absolute;
  left: 130px;
  top: 20px;
  background: #3269e5;
  background: linear-gradient(101.57deg, #41a1f5 0%, #3269e5 100%);
  border-radius: 4px;
  font-weight: 700;
  font-size: 10px;
  line-height: 15px;
  text-align: center;
  text-transform: uppercase;
  color: #ffffff;
  padding: 5px 10px;
}

//pc大屏幕适配
// @media screen and (min-width: 1440px) {
//   .sidebar{
//     left: 50%;
//     margin-left: -720px;
//   }
// }

.drawer {
  display: none;
}
.menuLogo {
  display: none;
}

.logo {
  margin-top: 46px;
  margin-left: 30px;
  margin-bottom: 55px;
}

.listItem {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 11px 18px 11px 32px;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #717c7a;
  margin-top: 4px;
  margin-bottom: 4px;
  cursor: pointer;
}

.listItemActive {
  position: relative;
  color: #0355bf;
  &::before {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 5px;
    background-color: #0355bf;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
  }
}

.listItemIcon {
  width: 28px;
  margin-right: 10px;
}

.tail {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: left;
  color: #a0a8a7;
  position: absolute;
  height: 96px;
  width: 100%;
  padding: 0 20px;
  left: 0;
  bottom: 25px;
  word-break: break-word;
  white-space: normal;
}

.email {
  display: inline-block;
  color: #0355bf;
}

.version {
  font-size: 16px;
  line-height: 24px;
  color: #a0a8a7;
  text-align: left;
  margin-bottom: 16px;
}

.sideIcon {
  width: 28px;
  height: 28px;
}

.toolbar {
  display: none;
}

/* 小于1024px tablet */
@media screen and (max-width: 1024px) {
  @include tablet();
}
/* 小于480px mobile */
@media screen and (max-width: 480px) {
  @include mobile();
}
