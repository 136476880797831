@mixin mobile {
  .page {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  .img {
    width: 300px;
    position: relative;
    overflow: hidden;
  }

  .text {
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 54px;
    color: #232323;
    margin-top: 30px;
    text-align: center;
  }
}
