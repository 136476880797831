@import './index-tablet.module.scss';
@import './index-mobile.module.scss';

.voyagerPassWrap {
  background: linear-gradient(0deg, rgba(3, 85, 191, 0.06) 0%, rgba(3, 85, 191, 0.06) 100%), #fff;
  overflow: hidden;
}

.voyagerPass {
  max-width: 1440px;
  margin: 0 auto;
  padding: 54px 100px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 100px;
}

.voyagerLeft {
  max-width: 600px;

  .nftName {
    color: #232323;
    font-size: 48px;
    font-style: normal;
    font-weight: 600;
    line-height: 56px;
  }
  .vpDesc {
    color: #7a7a7a;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
  }
}

.voyagerRight {
  width: 344px;
  height: auto;
  border-radius: 14px;
  background: #fff;
  overflow: hidden;
  margin-right: 90px;
  flex-shrink: 0;
  img {
    width: 100%;
  }
}

.nftBenefits {
  .vpDesc {
    font-weight: 400;
    font-size: 18px;
    line-height: 26px;
    color: #717c7a;
    margin-top: 30px;
  }

  .vpBenefit {
    margin-top: 16px;
  }
  .vpBenefitItem {
    display: flex;
    gap: 12px;
    color: #232323;
    align-items: center;
    margin-bottom: 10px;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
  }
}

.disable {
  background: #ddd;
  color: #fff;
  cursor: not-allowed;
  &:hover {
    color: #fff;
  }
}

.box {
  margin-top: 38px;
  width: 100%;
  gap: 32px;
  border-radius: 18px;
  display: flex;
  justify-content: flex-start;
  > button {
    min-width: 234px;
  }
}

.vpInfo {
  padding: 16px 20px;
  display: flex;
  justify-content: space-between;
  height: 80px;
  .vpInfoTitle {
    color: #7a7a7a;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px; /* 157.143% */
  }
}

.vpInfoLoading {
  padding: 16px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80px;
}

.countdown {
  background-image: url('/svg/mint/bg1.svg?v=1');
  background-size: 100% 100%;
  margin-top: 40px;
  height: 136px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  border-radius: 0;
}

.mintDialog {
  .mintDialogTitle {
    width: 105px;
    height: 105px;
  }
  .mintDialogContent {
    font-size: 22px;
    font-weight: 600;
    line-height: 34px;
    text-align: center;
    margin: 30px auto;
  }
  .mintDialogBtnGroup {
    display: flex;
    gap: 20px;
  }
}

.connectWallet {
  color: #a5a5a5;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 157.143% */
  margin-top: 12px;
  margin-left: 4px;
}

/* 小于1024px tablet */
@media screen and (max-width: 1024px) {
  @include tablet();
}
/* 小于480px mobile */
@media screen and (max-width: 480px) {
  @include mobile();
}
