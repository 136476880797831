@import './index-tablet.module.scss';
@import './index-mobile.module.scss';

.box {
  max-width: 680px;
  padding: 0 40px 6px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  min-width: 440px;

  .img {
    width: 120px;
    height: 120px;
  }

  .text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
  }

  .title {
    text-align: left;
    font-weight: 500;
    font-size: 22px;
    line-height: 34px;
    color: #232323;
    margin-bottom: 10px;
    width: 100%;
  }
  .desc {
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #4f4f4f;
    text-align: left;
  }

  .btnBox {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 34px;
    gap: 30px;
  }

  .btn1 {
    min-width: 120px;
    width: 100%;
    overflow: hidden;
  }
  .btn2 {
    min-width: 120px;
    width: 100%;
  }
}

.minWidthBox {
  min-width: 660px;
}

/* 小于1024px tablet */
@media screen and (max-width: 1024px) {
  @include tablet();
}

/* 小于480px mobile */
@media screen and (max-width: 480px) {
  @include mobile();
}
