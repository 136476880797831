@mixin tablet {
  .sidebarWarp {
    height: 70px;
  }

  .sidebar {
    display: block;
    background-color: transparent;
    box-shadow: none;
    height: auto;
    margin-right: 0;
    width: 100%;
    border-bottom: 1px solid #f1f2f2;
  }

  .menuLogo {
    display: flex;
    align-items: center;
    padding: 16px 24px;
    height: 70px;
    background-color: #fff;
    width: 100% !important;
  }

  .iconButton {
    background-color: #fafafa;
    border-radius: 12px;
    margin-right: 10px;
    color: #0355bf;
  }

  .smallLogo {
    height: 16px;
    width: 48px;
  }

  .iconWrapper {
    margin: 24px;
  }

  .list {
    width: 380px;
  }

  .drawer {
    display: block;
  }
}
