@import './index-tablet.module.scss';
@import './index-mobile.module.scss';

.cookiePolicy {
  background-color: #fff;
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 600px;
  padding: 30px;
  z-index: 2;
  border: 1px solid #f1f2f2;
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.03);
  border-radius: 24px;

  .title {
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    color: #232323;
  }

  .subTitle {
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;
    color: #232323;
    margin-top: 6px;
  }

  .content {
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    color: #4f4f4f;
    margin-top: 16px;
  }

  .btnGroup {
    display: flex;
    justify-content: space-between;
    gap: 20px;
    margin-top: 20px;
    > button {
      width: 100%;
    }
    > button:first-child {
      color: #0355bf;
      border-color: #0355bf;
    }
  }
  .customizeBtn {
    margin-top: 20px;
    width: 100%;
    // padding: 14px 30px;
    // font-weight: 500;
    // font-size: 16px;
    // line-height: 24px;
    // display: flex;
    // align-items: center;
    // text-align: center;
    // color: #0355BF;
    // background: #ffffff;
    // border-radius: 8px;
    // white-space: nowrap;
    // cursor: pointer;
  }

  a {
    color: #0355bf;
  }
}

.cookieSettings {
  a {
    color: #0355bf;
  }

  .desc {
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #4f4f4f;
  }
  .cookieList {
    background: #ffffff;
    border: 1px solid #f1f2f2;
    border-radius: 10px;
    margin-top: 20px;
    .cookieItem {
      padding: 8px 15px;
      display: flex;
      gap: 10px;
      box-shadow: inset 0px -1px 0px #f1f2f2;
      align-items: flex-start;
      &:last-child {
        box-shadow: none;
      }
      .left {
        flex-shrink: 0;
        margin-top: 8px;
        cursor: pointer;
      }
      .right {
        width: 100%;
      }
      .line1 {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-weight: 500;
        font-size: 14px;
        line-height: 22px;
        color: #232323;
        cursor: pointer;
      }
      .line2 {
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
        color: #7a7a7a;
      }
    }
  }
}

/* 小于1024px tablet */
@media screen and (max-width: 1024px) {
  @include tablet();
}

/* 小于480px mobile */
@media screen and (max-width: 480px) {
  @include mobile();
}
