@import './index-tablet.module.scss';
@import './index-mobile.module.scss';

.headerWrap {
  background-color: #fff;
  position: sticky;
  top: 0;
  z-index: 2;
}

.header {
  max-width: 1440px;
  padding-left: 100px;
  padding-right: 100px;
  position: relative;
  margin: 0 auto;
  height: 70px;
  width: 100%;
  font-weight: 600;
  font-size: 22px;
  line-height: 34px;
  color: #232323;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #fff;
  z-index: 2;
  .left,
  .right {
    display: flex;
    align-items: center;
  }

  .user,
  .balance {
    display: flex;
    align-items: center;
    padding: 8px 20px 8px 16px;
    max-width: 240px;
    overflow: hidden;
    text-overflow: ellipsis;
    gap: 6px;
    height: 38px;
    background: #fafafa;
    border-radius: 8px;
    margin-left: 24px;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    text-align: center;
    color: #232323;
    white-space: nowrap;
    cursor: pointer;
    svg {
      display: inline-block;
    }
    .userEmail {
      margin-left: 0;
    }
  }

  .language {
    height: 38px;
    background: #fafafa;
    border-radius: 8px;
    margin-left: 24px;
  }
  .logo {
    width: 100px;
    margin-right: 80px;
  }
  .headerBtn {
    height: 38px;
    line-height: 38px;
    margin-left: 20px;
  }
}

.wallet {
  display: flex;
  align-items: center;
  padding: 8px 20px 8px 16px;
  gap: 16px;
  height: 38px;
  background: #fafafa;
  border-radius: 8px;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  text-align: center;
  color: #232323;
  cursor: pointer;
  margin-left: 24px;
  .metamaskIcon {
    width: 24px;
    height: 24px;
  }
}

.connectedBox {
  display: flex;
}

.menu {
  margin-top: 10px;
  width: 260px;
  :global {
    .MuiMenuItem-root {
      width: 228px;
    }
  }
}

.firstMenuItem {
  padding: 4px 16px 8px;
  border-bottom: 1px solid #f1f2f2;
  max-width: 300px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.walletMenu {
  display: flex;
  align-items: center;
  padding: 4px 18px;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #232323;
  gap: 20px;
  &:hover {
    color: #232323 !important;
  }
  svg {
    &:hover {
      color: #0355bf !important;
    }
  }
}

.addNewID {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 20px 8px 14px;
  gap: 10px;
  height: 38px;
  background: #fafafa;
  border-radius: 8px;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  color: #717c7a;
  margin-left: 30px;
  cursor: pointer;
}

.beta {
  position: absolute;
  left: 220px;
  top: 10px;
  background: #3269e5;
  background: linear-gradient(101.57deg, #41a1f5 0%, #3269e5 100%);
  border-radius: 4px;
  font-weight: 700;
  font-size: 10px;
  line-height: 15px;
  text-align: center;
  text-transform: uppercase;
  color: #ffffff;
  padding: 5px 10px;
}

.networkTitle {
  border-top: 1px solid #f1f2f2;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  padding-left: 20px;
  color: #a0a8a7 !important;
  text-align: center;
  :hover {
    color: #a0a8a7 !important;
  }
}

.network {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #232323;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 20px;
  padding-right: 20px;
  cursor: pointer;
}

.newNetwork {
  padding: 8px 5px !important;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #121212;
  text-align: center;
  display: flex;
  align-items: center;
  gap: 10px;
  padding-left: 0;
  padding-right: 0;
  cursor: pointer;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
}

.bitFavIcon {
  width: 63px;
  margin-left: 20px;
}

.currentNewNetwork {
  border-radius: 8px;
  background: #fbfbfb;
  padding: 8px 16px !important;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #121212;
  text-align: center;
  display: flex;
  align-items: center;
  gap: 10px;
  padding-left: 0;
  padding-right: 0;
  cursor: pointer;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
}

.connectWalletButton {
  margin-left: 25px;
}

.logoutIcon {
  margin-left: 10px;
}

.header2 {
  display: flex;
  width: 100%;
  margin: 0 auto;
  height: 70px;
  justify-content: space-between;
  align-items: center;
  background: #0355bf;
}

.innerHeader2 {
  width: 100%;
  max-width: 1440px;
  padding-left: 100px;
  padding-right: 100px;
  margin: 0 auto;
  height: 70px;
  display: flex;
  align-items: center;

  .link {
    margin-right: 20px;
    padding-right: 20px;
    white-space: nowrap;
    font-weight: 500;
    color: #fff;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    border-right: 1px solid rgba(255, 255, 255, 0.5);
    &:last-child {
      border-right: none;
    }
  }
}

.header3 {
  display: none;
}

.addWalletButton {
  color: #121212;
  font-size: 14px !important;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  border-top: 1px solid #dbdbdb;
}

/* 小于720px tablet */
@media screen and (max-width: 720px) {
  .header {
    .language {
      display: none;
    }
  }
}

/* 小于1024px tablet */
@media screen and (max-width: 1024px) {
  @include tablet();
}

/* 小于480px mobile */
@media screen and (max-width: 480px) {
  @include mobile();
}
