@mixin mobile {
  .footer {
    flex-direction: column;
    padding: 30px 14px;
    text-align: center;
    align-items: center;
    justify-content: center;
    .contact {
      justify-content: center;
    }
    .email {
      font-weight: 400;
      font-size: 16px;
      line-height: 28px;
      text-align: center;
      flex-direction: column;
      gap: 20px;
    }
    .policy {
      display: flex;
      justify-content: center;
      gap: 0;
      margin-top: 20px;
      a {
        margin-right: 8px;
        &:after {
          padding-left: 8px;
        }
      }
    }
  }
}
