@import './index-tablet.module.scss';
@import './index-mobile.module.scss';

.page {
  margin: 0 auto;
  .main {
    margin: 0 auto;
  }
  .banner {
    margin: 0 24px;
  }
  .bannerInner {
    max-width: 1440px;
    margin: 0 auto;
    background-image: url('/img/referral/bg.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    padding: 100px 70px 106px;
    margin-bottom: 120px;
    border-radius: 0 24px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
  }
  .bannerDesc {
    color: #232323;
    font-family: 'Red Hat Display';
    font-size: 70px;
    font-style: normal;
    font-weight: 400;
    line-height: 86px;
    letter-spacing: 1.4px;
    text-align: center;
    margin: 0 auto;
  }
  .bannerDescContent {
    color: rgba(18, 18, 18, 0.6);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    margin-top: 24px;
    margin: 20px auto;
    text-align: center;
  }
  .bannerButton {
    display: flex;
    padding: 16px 64px;
    justify-content: center;
    align-items: center;
    gap: 14px;
    border-radius: 8px;
    background: #8051fb;
    color: #fff;
    font-size: 18px;
    font-weight: 500;
    line-height: 28px; /* 155.556% */
    margin: 20px auto;
    cursor: pointer;
  }

  .customCountdownWrap {
    position: relative;
    width: 100%;
  }
  .customCountdown {
    position: absolute;
    border-radius: 24px;
    border: 2px solid #c2a9ff;
    background: var(--white-white, #fff);
    color: #8051fb;
    font-size: 52px;
    font-weight: 800;
    line-height: 56px; /* 107.692% */
    width: 100%;
    margin-top: 50px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 130px;
    padding-bottom: 16px;

    :global {
      .label {
        color: #8051fb;
      }

      .colon {
        margin-left: 60px;
        margin-right: 60px;
      }

      .days {
        margin-right: 0 !important;
      }
    }
  }
  .socialMedia {
    display: flex;
    align-items: center;
    gap: 20px;
    justify-content: center;
    margin: 32px auto 0;
    > img {
      cursor: pointer;
    }
  }
  .stepperWrapMobile {
    display: none;
  }
  .stepperWrapInner {
    max-width: 1300px;
    margin: 40px auto;
    border-radius: 32px;
    background: #ede9ff;
    padding: 48px 148px 360px 48px;
  }
  .stepperWrap {
    margin-right: 24px;
    margin-left: 24px;
  }
  :global {
    .MuiStepConnector-line {
      height: 8px;
      background: #fff;
      border-color: #fff;
      border-radius: 4px;
    }
    .Mui-completed,
    .Mui-active {
      .MuiStepConnector-line {
        height: 8px;
        background: #8051fb;
        border-color: #8051fb;
        border-radius: 4px;
      }
    }
    .Mui-completed,
    .Mui-active {
      .jiangli {
        .jine {
          color: #8051fb;
          font-size: 64px;
          font-weight: 800;
          letter-spacing: 2.56px;
        }
        .button {
          background: #8051fb;
          cursor: pointer;
        }
      }
    }
  }

  .claimed {
    color: #fff !important;
    background: #ede9ff !important;
  }

  .jiangli {
    display: flex;
    padding: 24px 32px 32px 32px;
    flex-direction: column;
    align-items: center;
    gap: 24px;
    align-self: stretch;
    border-radius: 20px;
    background: #fff;
    position: absolute;
    transform: translate(-50%, 10%);
    margin-left: 20px;
    width: 260px;
    > svg {
      position: absolute;
      top: -13px;
    }
    .jine {
      color: #ede9ff;
      font-size: 64px;
      font-weight: 800;
      letter-spacing: 2.56px;
    }
    .shuoming {
      color: #5e5e5e;
      text-align: center;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
    }
    .button {
      display: flex;
      padding: 14px 20px;
      justify-content: center;
      align-items: center;
      gap: 8px;
      align-self: stretch;
      color: #fff;
      font-size: 14px;
      font-weight: 500;
      line-height: 20px; /* 142.857% */
      border-radius: 8px;
      background: #ede9ff;
    }
  }
  .stepLabelContainer {
    color: #837d82;
  }
  .stepLabel {
    background: #fff;
    display: flex;
    width: 40px;
    height: 40px;
    padding: 4px 4px 5px 4px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex-shrink: 0;
    border-radius: 20px;
    color: #dbdbdb;
    text-align: center;
    font-size: 20px;
    font-weight: 800;
  }

  .stepLabelHighlight {
    color: #121212;
  }

  .stepLabelSelected {
    background: #8051fb;
    color: #fff;
  }

  .referralsRecordsInner {
    max-width: 1300px;
    margin: 40px auto;
    display: flex;
    gap: 40px;
  }

  .referralsRecords {
    margin-right: 24px;
    margin-left: 24px;
    .referralsRecord {
      padding: 40px 48px 48px 48px;
      gap: 40px;
      flex: 1 0 0;
      border-radius: 32px;
      background: #f5f5f5;
    }
    .referralsRecordItem {
      color: #121212;
      font-size: 52px;
      font-weight: 800;
      letter-spacing: 2.08px;
      display: flex;
      align-items: center;
      gap: 10px;
    }
    .referralsRecordItem2 {
      color: #121212;
      font-size: 18px;
      font-weight: 400;
      line-height: 28px; /* 155.556% */
    }
  }

  .qrcodeWrap {
    background: #fff url('/img/referral/bg2.png?v=1');
    background-size: 100% 100%;
    width: 400px;
    padding: 24px;
    height: 540px;
    position: absolute;
    overflow: hidden;
    border-radius: 0;
    position: absolute;
    top: 80px;
    left: -10000px;
    z-index: 1;
    .title {
      color: #121212;
      font-size: 32px;
      font-weight: 700;
      line-height: 40px;
    }

    .desc {
      color: #383838;
      font-size: 16px;
      font-weight: 400;
      line-height: 32px;
    }

    .qrcodeBottom {
      color: #0056c6;
      font-size: 30px;
      font-weight: 500;
      line-height: 36px;
      position: absolute;
      background: #fff;
      border-radius: 24px 24px 0 0;
      bottom: 0;
      width: 100%;
      padding: 12px 12px 12px 24px;
      left: 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
      svg {
        width: 80px;
        height: 40px;
      }
      .link {
        font-size: 14px;
      }
    }

    .qrcodeImg {
      width: 120px;
      height: 120px;
    }
  }

  .title {
    color: #232323;
    font-size: 48px;
    font-style: normal;
    font-weight: 600;
    line-height: 56px;
  }
  .desc {
    color: #4f4f4f;
    font-size: 16px;
    line-height: 24px;
    margin-top: 24px;
  }

  .itemTitle {
    color: #232323;
    font-size: 24px;
    font-weight: 600;
    line-height: 32px;
    margin-top: 46px;
  }

  .itemUl,
  .itemUl2 {
    color: #4f4f4f;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    margin-top: 18px;
    list-style-type: disc;
    li {
      margin-left: 24px;
      line-height: 28px;
    }
  }

  .itemUl2 {
    margin-left: 40px;
  }

  .emailLink {
    color: #0354bf;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    text-decoration-line: underline;
  }

  .contactUS {
    padding: 4px 56px 56px 56px;
    gap: 18px;
    border-radius: 24px;
    background-image: url('/img/bid/20241016-154446.png');
    background-size: auto 100%;
    background-repeat: no-repeat;
    max-width: 1288px;
    width: 90%;
    margin: 64px auto;
  }

  .container {
    margin: 64px auto;
    max-width: 1288px;
    padding-left: 24px;
    padding-right: 24px;
  }

  .container2 {
    background: #fbfbfb;
  }

  .container2Inner {
    display: flex;
    max-width: 1288px;
    margin: 0 auto;
    padding: 14px 24px 64px;
    flex-direction: column;
    align-items: flex-start;
  }

  .sendButton {
    display: inline-flex;
    padding: 14px 20px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 8px;
    background: #0354bf;
    margin-top: 24px;
    color: #fff;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
  }
}

/* 小于1024px tablet */
@media screen and (max-width: 1024px) {
  @include tablet();
}
/* 小于480px mobile */
@media screen and (max-width: 480px) {
  @include mobile();
}
