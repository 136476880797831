@mixin tablet {
  .redeemBgWrap {
    min-height: calc(100vh - 378px);
  }
  .redeemBg {
    padding: 40px 24px;
    flex-direction: column;
    gap: 40px;
    h1 {
      color: #232323;
      font-size: 42px;
      font-style: normal;
      font-weight: 600;
      line-height: 56px; /* 116.667% */
    }

    .redeemLeft {
      max-width: 700px;
    }
  }
  .resultBox {
    width: 100%;
  }

  .nftAddressBox {
    margin-top: 40px;
  }

  .contract {
    color: #a5a5a5;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px; /* 157.143% */
  }
}
