@mixin mobile {
  .sidebarWarp {
    height: 50px;
  }

  .beta {
    position: absolute;
    left: 120px;
    top: 10px;
  }

  .list {
    width: 100%;
  }

  .drawer {
    :global {
      .MuiDrawer-paper {
        width: 100%;
      }
    }
  }

  .menuLogo {
    padding: 14px;
    height: 50px;
  }

  .toolbar {
    margin-top: 10px;
    padding-top: 10px;
    border-top: 1px solid #f1f2f2;
    display: block;
  }

  .iconWrapper {
    margin: 14px;
  }

  .listItem {
    padding: 8px 18px 8px 24px;
  }

  .sidebarLoginBtn {
    width: calc(100% - 40px);
    margin-left: 20px;
    margin-right: 20px;
    margin-top: 20px;
  }
}
