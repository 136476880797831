@mixin mobile {
  .page {
    margin: 0 auto;
    .main {
      max-width: 100%;
      margin: 0 auto;
    }
    .banner {
      margin: 14px;
    }
    .bannerInner {
      max-width: 100%;
      margin: 14px auto;
      background-image: url('/img/bid/banner.png');
      background-size: cover;
      background-repeat: no-repeat;
      padding: 14px 14px;
      border-radius: 14px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
    }
    .bannerDesc {
      color: #fff;
      font-family: 'Red Hat Display';
      font-size: 24px;
      font-style: normal;
      font-weight: 800;
      line-height: 34px;
      letter-spacing: 1.4px;
    }
    .bannerDescContent {
      color: #fff;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      margin-top: 20px;
      width: 100%;
    }
    .bannerButton {
      margin-top: 24px;
      cursor: pointer;
      display: flex;
      padding: 8px 12px;
      justify-content: center;
      align-items: center;
      gap: 2px;
      color: #232323;
      font-size: 12px;
      font-weight: 500;
      line-height: 20px;
      background: #fff;
      border-radius: 8px;
      border: 1px solid #dbdbdb;
      background: #fff;
      box-shadow: 0px 3px 6px -3px rgba(0, 0, 0, 0.05), 0px 2px 4px -2px rgba(0, 0, 0, 0.05),
        0px 1px 2px -1px rgba(0, 0, 0, 0.05), 0px 1px 0px -1px rgba(0, 0, 0, 0.05);
    }
    .title {
      color: #232323;
      font-size: 32px;
      font-style: normal;
      font-weight: 600;
      line-height: 38px;
    }
    .desc {
      color: #4f4f4f;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      margin-top: 14px;
    }

    .itemTitle {
      color: #232323;
      font-size: 20px;
      font-weight: 600;
      line-height: 32px;
      margin-top: 22px;
    }

    .itemUl {
      color: #4f4f4f;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 18px;
      margin-top: 12px;
      list-style-type: disc;
      li {
        margin-left: 24px;
        line-height: 20px;
      }
    }

    .emailLink {
      color: #0354bf;
      font-size: 12px;
      font-weight: 600;
      line-height: 24px;
      text-decoration-line: underline;
    }

    .contactUS {
      padding: 1px 16px 26px 16px;
      gap: 18px;
      border-radius: 24px;
      max-width: 90%;
      margin: 34px auto;
    }

    .container {
      margin: 24px auto;
      max-width: 100%;
      padding: 0 14px;
    }

    .container2 {
      background: #fbfbfb;
    }

    .container2Inner {
      display: flex;
      max-width: 100%;
      margin: 0 auto;
      padding: 0px 24px 24px;
      flex-direction: column;
      align-items: flex-start;
    }

    .sendButton {
      display: flex;
      padding: 14px 20px;
      justify-content: center;
      align-items: center;
      gap: 8px;
      border-radius: 8px;
      background: #0354bf;
      margin-top: 24px;
      color: #fff;
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
    }
  }
}
