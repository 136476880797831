@mixin tablet {

  .socialMedia {
    display: flex;
    align-items: center;
    gap: 10px;
    justify-content: center;
    // margin: 32px auto 0;
    > img,.img {
      cursor: pointer;
      width:40px;
    }
  
    .shareText {
      display: flex;
      align-items: center;
      gap: 10px;
      color: #a5a5a5;
      font-size: 12px;
      font-weight: 400;
      line-height: 24px; /* 150% */
      border-radius: 30px;
      background: #fff;
      padding: 10px 24px;
      align-items: center;
      gap: 12px;
      flex: 1 0 0;
      .shareInnerText {
        display: inline-block;
        width: 60px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        color: #121212;
      }
      svg {
        flex-shrink: 0;
        cursor: pointer;
      }
    }
  }
  
  .qrcodeWrap {
    background: #fff url('/img/referral/bg2.png?v=1');
    background-size: 100% 100%;
    width: 400px;
    padding: 24px;
    height: 540px;
    position: absolute;
    overflow: hidden;
    border-radius: 0;
    position: absolute;
    top: 80px;
    left: -10000px;
    z-index: 1;
    .title {
      color: #121212;
      font-size: 32px;
      font-weight: 700;
      line-height: 40px;
    }
  
    .desc {
      color: #383838;
      font-size: 16px;
      font-weight: 400;
      line-height: 32px;
      margin-top: 10px;
    }
  
    .qrcodeBottom {
      color: #0056c6;
      font-size: 30px;
      font-weight: 500;
      line-height: 36px;
      position: absolute;
      background: #fff;
      border-radius: 24px 24px 0 0;
      bottom: 0;
      width: 100%;
      padding: 12px 12px 12px 24px;
      left: 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
      svg {
        width: 80px;
        height: 40px;
      }
      .link {
        font-size: 14px;
      }
    }
  
    .qrcodeImg {
      width: 120px;
      height: 120px;
    }
  }

  .page {
    margin: 0 auto;
    .main {
      max-width: 100%;
      margin: 0 auto;
    }
    .banner {
      margin: 14px;
    }
    .bannerInner {
      max-width: 100%;
      margin: 14px auto;
      background-image: url('/img/bid/banner.png');
      background-size: cover;
      background-repeat: no-repeat;
      padding: 140px 70px;
      border-radius: 24px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
    }
    .bannerDesc {
      color: #fff;
      font-family: 'Red Hat Display';
      font-size: 40px;
      font-style: normal;
      font-weight: 800;
      line-height: 46px;
      letter-spacing: 1.4px;
    }
    .bannerDescContent {
      color: #fff;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      margin-top: 24px;
      width: 500px;
    }
    .bannerButton {
      margin-top: 24px;
      cursor: pointer;
      display: flex;
      padding: 10px 16px;
      justify-content: center;
      align-items: center;
      gap: 8px;
      color: #232323;
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      background: #fff;
      border-radius: 8px;
      border: 1px solid #dbdbdb;
      background: #fff;
      box-shadow: 0px 3px 6px -3px rgba(0, 0, 0, 0.05), 0px 2px 4px -2px rgba(0, 0, 0, 0.05),
        0px 1px 2px -1px rgba(0, 0, 0, 0.05), 0px 1px 0px -1px rgba(0, 0, 0, 0.05);
    }
    .title {
      color: #232323;
      font-size: 42px;
      font-style: normal;
      font-weight: 600;
      line-height: 56px;
    }
    .desc {
      color: #4f4f4f;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
      margin-top: 24px;
    }

    .itemTitle {
      color: #232323;
      font-size: 24px;
      font-weight: 600;
      line-height: 32px;
      margin-top: 46px;
    }

    .itemUl {
      color: #4f4f4f;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      margin-top: 18px;
      list-style-type: disc;
      li {
        margin-left: 24px;
        line-height: 28px;
      }
    }

    .emailLink {
      color: #0354bf;
      font-size: 16px;
      font-weight: 600;
      line-height: 24px;
      text-decoration-line: underline;
    }

    .contactUS {
      padding: 4px 56px 56px 56px;
      gap: 18px;
      border-radius: 24px;

      max-width: 90%;
      margin: 34px auto;
    }

    .container {
      margin: 24px auto;
      max-width: 100%;
      padding: 0 14px;
    }

    .container2 {
      background: #fbfbfb;
    }

    .container2Inner {
      display: flex;
      max-width: 100%;
      margin: 0 auto;
      padding: 14px 24px 64px;
      flex-direction: column;
      align-items: flex-start;
    }

    .sendButton {
      display: inline-flex;
      padding: 14px 20px;
      justify-content: center;
      align-items: center;
      gap: 8px;
      border-radius: 8px;
      background: #0354bf;
      margin-top: 24px;
      color: #fff;
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
    }
  }
}
