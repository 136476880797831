@import './index-tablet.module.scss';
@import './index-mobile.module.scss';

.root {
  animation-duration: 0.15s;
}

.menu {
  background-color: #fff;
  border: 1px solid #f1f2f2;
  box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.03);
  border-radius: 12px;
  padding: 6px 10px;
  .menuItem {
    padding: 10px 18px;
    font-size: 16px;
    line-height: 24px;
    color: #232323;
    cursor: pointer;
    white-space: nowrap;
    display: flex;
    &:hover {
      color: #0355bf;
    }
  }
}

/* 小于1024px tablet */
@media screen and (max-width: 1024px) {
  @include tablet();
}
/* 小于480px mobile */
@media screen and (max-width: 480px) {
  @include mobile();
}
