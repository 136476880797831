.countdown {
  font-weight: 600;
  font-size: 40px;
  height: 90px;
  text-align: left;
  color: #d1cbe6;
  display: flex;
  align-items: center;
  letter-spacing: 0.16rem;
  margin-top: -15px;
  .days,
  .hours,
  .mins,
  .secs {
    position: relative;
  }
  .num {
    display: inline-block;
    width: 30px;
    text-align: center;
  }
  .colon {
    display: inline-block;
    margin: 0 14px;
  }
  .label {
    position: absolute;
    font-weight: 400;
    font-size: 14px;
    line-height: 25px;
    text-align: center;
    letter-spacing: 0.02em;
    color: #b1adbe;
    bottom: -20px;
    left: 50%;
    transform: translateX(-50%);
  }
}

.hasDays {
  font-size: 32px;
  letter-spacing: 0.1em;
  .num{
    width: 28px;
  }
  .colon{
    margin: 0 5px;
  }
  .days{
    margin-right: 10px;
  }
}

@media (min-width: 1024px) { 
  .countdown{
    font-size: 60px;
    .num{
      width: 44px;
    }
  }
  .hasDays {
    font-size: 56px;
    letter-spacing: 0.1em;
    .num{
      width: 40px;
    }
    .colon{
      margin: 0 10px;
    }
    .days{
      margin-right: 30px;
    }
  }
}