@mixin mobile {
  .voyagerPass {
    padding: 14px;
    gap: 24px;
    justify-content: space-between;
    flex-direction: column;
  }

  .box {
    margin-top: 24px;
    flex-direction: column;
    gap: 14px;
    > button {
      width: 100%;
    }
  }

  .voyagerLeft {
    .nftName {
      font-size: 32px;
      font-style: normal;
      font-weight: 600;
      line-height: 44px; /* 112.5% */
    }

    .nftBenefits {
      .vpBenefitItem {
        font-size: 14px;
      }
    }
  }

  .voyagerRight {
    width: 100%;
  }

  .mintDialog {
    .mintDialogTitle {
      width: 85px;
      height: 85px;
    }
    .mintDialogContent {
      font-size: 18px;
      line-height: 26px;
      margin: 20px auto 30px;
    }
  }

  .connectWallet {
    display: block;
    width: 100%;
    text-align: center;
    color: #a0a8a7;
    font-size: 14px;
    margin: 20px auto 0;
  }
}
