@mixin tablet {
  .main {
    max-width: auto;
    padding-left: 24px;
    padding-right: 24px;
    margin: 0 auto;
  }
  .privilege {
    margin: 24px auto;
    align-items: center;
    .privilegeImage {
      width: 40%;
    }
  }
  .mintNotice {
    margin: 40px auto;
  }

  .faqsHeader {
    margin: 40px auto 20px;
  }
}
