@mixin mobile {
  .privilege {
    flex-direction: column-reverse;
    gap: 26px;
    .privilegeImage {
      width: 100%;
    }

    .privilegeContent {
      .privilegeTitle {
        font-size: 26px;
        font-style: normal;
        font-weight: 600;
        line-height: 34px; /* 123.077% */
      }
      .privilegeDesc {
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px; /* 157.143% */
      }
      .privilegeTip {
        margin-top: 20px;
      }
    }
  }
  .mintNotice {
    padding: 24px;
    border-radius: 14px;
    .mintNoticeTitle {
      font-weight: 700;
      font-size: 24px;
      line-height: 36px;
      color: #232323;
    }
    .mintNoticeContent {
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
    }
  }
}
