@mixin tablet {
  .page {
    // .huodongbannerWrap {
    //   font-size: 16px;
    //   .huodongbanner {
    //     max-width: 100%;
    //     .button {
    //       font-size: 12px;
    //     }
    //   }
    // }

    .bannerWrap {
      .banner {
        padding: 24px;
        padding-bottom: 50px;

        .benefits {
          width: 100%;
          .benefitItem {
            width: 100% !important;
            white-space: normal;
          }
        }
        .web3Id {
          width: 100%;

          .legal {
            font-size: 76px;
            line-height: 76px;
          }
          .point {
            width: 200px;
            margin-top: 14px;
          }
        }
        .btnGroup {
          flex-wrap: wrap;
          align-items: center;
          gap: 20px;
          .nftBtn {
            min-height: 42px;
            height: auto;
          }
        }

        .linkGroup {
          gap: 20px;
        }
      }

      .banner1 {
        background-image: url('/img/index/mobileBanner.png?v=1');
        background-position: right center;
        background-size: 48%;
      }
      .banner2 {
        background-image: url('/img/index/card2.png');
        background-position: right center;
        background-size: 48%;
        padding-top: 25px;
        .program {
          margin-top: 20px;
          .desc {
            white-space: normal;
          }
        }
      }
    }
    .bannerDesc {
      padding: 30px;
      width: calc(100% - 60px);
      .bannerDescTitle {
        font-weight: 700;
        font-size: 26px;
        line-height: 40px;
        color: #232323;
      }
      .bannerDescContent {
        font-weight: 400;
        font-size: 16px;
        line-height: 28px;
        text-align: left;
        color: #717c7a;
      }
    }

    .indexCustomBtn {
      padding-right: 12px;
      padding-left: 12px;
      min-width: 180px;
      display: flex;
      gap: 10px;
    }

    .vp {
      flex-direction: column;
      justify-content: center;
      gap: 40px;
      margin: 80px auto 0;
      .vpContent {
        align-items: center;
        justify-content: center;
        text-align: center;
        padding-right: 30px;
        padding-left: 30px;
      }
      .vpBtn {
        margin-top: 30px;
      }
      .vpVideo {
        text-align: center;
        margin: 0 auto;
      }
    }

    .rnsPoints {
      flex-direction: column-reverse;
      gap: 0;
      .rnsPointsContent {
        align-items: center;
        margin-top: 0;
        padding-right: 20px;
        padding-left: 20px;
      }
      .rnsPointsDesc {
        text-align: center;
      }
      .rnsPointsBtn {
        margin-top: 30px;
      }
      .discountImage {
        margin: 0 auto;
      }
    }

    .launch {
      flex-direction: column;
      gap: 0;
      padding: 60px 0 100px;
      width: 100%;
      .launchLeft {
        text-align: center;
        align-items: center;
        margin-right: 0;
        padding: 0 50px;
      }
      .launchRight {
        width: 100%;
        margin-top: 30px;
        padding: 40px;
      }
      .launchDesc {
        margin-top: 24px;
      }
      .launchLink {
        margin-top: 30px;
      }
    }

    .rnsPointsWrap,
    .saidWrap {
      width: calc(100% + 48px);
      margin-left: -24px;
      margin-right: -24px;
    }

    .said {
      flex-direction: column;
      padding: 80px 0;
      .saidContent {
        flex-direction: column;
        align-items: center;
        margin-top: 40px;
        gap: 40px;
      }
      .saidItem {
        padding: 30px;
        width: 100%;
        .saidItemWord {
          font-size: 16px;
        }
      }
    }
    .protocol {
      padding: 80px 0;
      .protocolContent {
        margin-top: 40px;
        flex-direction: column;
        gap: 20px;
        align-items: center;
      }
    }

    .kycList,
    .verificationList {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
      gap: 20px;
      padding-bottom: 100px;
      .verificationItem,
      .kycItem {
        width: 184px;
      }
    }

    .verificationList {
      margin-left: 24px;
      margin-right: 24px;
    }

    .verificationListsWrap {
      width: calc(100% + 48px);
      margin-left: -24px;
      margin-right: -24px;
    }
  }
  .cookiePolicyPaddingBottom {
    padding-bottom: 244px;
  }

  .lawTitle {
    color: #232323;
    font-size: 16px;
    font-weight: 500;
    line-height: 26px;
  }

  .lawTips {
    list-style: disc;
    color: #7a7a7a;
    font-size: 14px;
    line-height: 26px;
    padding-left: 20px;
    padding-right: 20px;
  }

  // 活动
  // .activityBanner {
  //   position: fixed;
  //   top: 70px;
  //   z-index: 2;
  //   display: flex;
  //   width: 100%;
  //   padding: 14px;
  //   justify-content: space-between;
  //   align-items: center;
  //   flex-shrink: 0;
  //   background: linear-gradient(0deg, rgba(255, 198, 50, 0.42) 0%, rgba(255, 198, 50, 0.42) 100%), #fff;
  //   .innerActivityBanner {
  //     font-size: 12px;
  //     max-width: 1440px;
  //     display: flex;
  //     align-items: center;
  //     justify-content: flex-start;
  //     gap: 20px;
  //     margin: 0 auto;
  //     > button {
  //       margin-left: 10px;
  //     }
  //     svg {
  //       flex-shrink: 0;
  //     }
  //   }
  // }

  .xMLParser {
    padding: 40px 24px 24px 24px;
    margin-top: 0;
    margin-bottom: 0;

    .sliderController,
    .chartCreator,
    .chartCreator1,
    .chartCreator2 {
      padding: 24px;
    }
    .sliderController {
      gap: 40px;
    }

    .formBuilder {
      flex-direction: column;
      > button {
        width: 100%;
      }
    }
  }

  .Frame1071 {
    padding: 40px 24px 24px 24px;

    .Frame836 {
      justify-content: flex-start;
      gap: 30px;
    }
    .MaskGroup1 {
      display: block;
      margin-top: 40px;
    }

    .MaskGroup,
    .MaskGroup2 {
      display: none;
    }

    .Frame1070 {
      flex-direction: column;
      gap: 44px;
    }
  }

  .Frame1072 {
    padding-top: 24px;
  }

  .mediaList {
    flex-wrap: wrap;
    height: auto;
    gap: 20px;
    justify-content: center;
    padding: 0;
    .mediaPic {
      max-width: 20%;
    }
  }
}
