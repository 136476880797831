@mixin mobile {
  .page {
    // .huodongbannerWrap {
    //   width: 100%;
    //   max-width: 100%;
    //   padding: 10px;
    //   font-size: 12px;

    //   .huodongbanner {
    //     max-width: 100%;
    //     gap: 10px;
    //     > img {
    //       width: 32px;
    //     }
    //     .button {
    //       font-size: 12px;
    //       white-space: normal;
    //       text-align: center;
    //     }
    //   }
    // }

    .bannerWrap {
      // 活动
      // margin-top: 100px;

      .swiperBanner {
        :global {
          .swiper-slide {
            height: 1px;
          }
          .swiper-slide-active {
            height: auto;
          }
        }
      }

      .banner {
        padding: 14px;
        background-image: none;
        flex-direction: column;
        .bannerLeft {
          max-width: 100%;
          width: 100%;
        }
        .program {
          font-size: 14px;
          line-height: 22px;
          color: #232323;
        }
        .web3Id {
          .legal {
            font-size: 70px;
            line-height: 90px;
          }
        }
        .newCard {
          img {
            width: 90%;
            margin: 0 auto;
          }
        }
        .benefits {
          .benefitItem {
            font-size: 14px;
            gap: 8px;

            &:last-child {
              margin-bottom: 0;
            }
            img {
              width: 18px;
              height: 18px;
            }
          }
        }
        .btnGroup {
          margin-top: 20px;
          a,
          button {
            width: 100%;
          }
        }
        .linkGroup {
          gap: 10px;
          flex-direction: column;
          margin-top: 24px;
          align-items: flex-start;
        }
      }

      .banner2 {
        .mobileCard {
          margin-top: 30px;
        }
      }
    }

    .bannerDesc {
      padding: 20px;
      width: calc(100% - 40px);
      margin-bottom: 60px;
      .bannerDescTitle {
        font-weight: 700;
        font-size: 24px;
        line-height: 36px;
        color: #232323;
      }
      .bannerDescContent {
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        color: #717c7a;
      }
    }

    .vp {
      flex-direction: column;
      justify-content: center;
      gap: 20px;
      margin: 50px auto 0;
      .vpTitle {
        font-weight: 700;
        font-size: 32px;
        line-height: 48px;
        color: #232323;
      }
      .vpContent {
        align-items: flex-start;
        justify-content: flex-start;
        text-align: left;
        padding-right: 0;
        padding-left: 0;
      }
      .vpBtn {
        margin-top: 30px;
        width: 100%;
        button {
          width: 100%;
        }
      }
      .vpVideo {
        width: 100%;
        height: auto;
        text-align: center;
        margin: 0 auto;
      }
    }

    .rnsPoints {
      flex-direction: column-reverse;
      gap: 0;
      margin: 40px auto 0;
      padding-top: 40px;
      padding-bottom: 20px;
      .rnsPointsContent {
        align-items: flex-start;
        margin-top: 0;
        padding-right: 20px;
        padding-left: 20px;
      }
      .rnsPointsTitle {
        font-weight: 700;
        font-size: 32px;
        line-height: 48px;
        color: #232323;
      }
      .rnsPointsDesc {
        text-align: left;
        margin-top: 20px;
      }
      .rnsPointsBtn {
        margin-top: 30px;
        width: 100%;
        button {
          width: 100%;
        }
      }
      .discountImage {
        margin: 0 auto;
      }
    }

    .launch {
      flex-direction: column;
      gap: 0;
      padding: 10px 0 44px;
      width: 100%;
      .launchLeft {
        text-align: left;
        align-items: flex-start;
        margin-right: 0;
        padding: 0px;
      }
      .launchRight {
        width: 100%;
        margin-top: 20px;
        padding: 20px;
        .recommend {
          width: 100%;
          gap: 40px;
          margin-bottom: 30px;
          .recommendWord {
            margin-top: 20px;
            font-weight: 600;
            font-size: 16px;
            line-height: 26px;
            color: #232323;
          }
        }
      }
      .launchDesc {
        margin-top: 24px;
      }
      .launchLink {
        margin-top: 30px;
      }
      .launchTitle {
        font-weight: 700;
        font-size: 32px;
        line-height: 48px;
        color: #232323;
      }
    }

    .rnsPointsWrap,
    .saidWrap {
      width: calc(100% + 28px);
      margin-left: -14px;
      margin-right: -14px;
    }

    .said {
      flex-direction: column;
      padding: 26px 0 50px;
      .saidTitle {
        font-weight: 700;
        font-size: 32px;
        line-height: 48px;
        color: #232323;
        text-align: left;
        padding-left: 14px;
      }
      .saidContent {
        flex-direction: column;
        align-items: center;
        margin-top: 30px;
        gap: 24px;
        padding: 0 14px;
      }
      .saidItem {
        padding: 24px;
        width: 100%;
        .saidItemWord {
          margin-top: 20px;
          font-weight: 600;
          font-size: 16px;
          line-height: 26px;
          color: #232323;
        }
        .saidItemLink {
          bottom: 24px;
        }
        .saidItemPosition {
          font-weight: 400;
          font-size: 14px;
          line-height: 22px;
          color: #717c7a;
        }
      }
    }
    .protocol {
      padding: 40px 0;
      .protocolTip {
        text-align: left;
      }
      .protocolTitle {
        text-align: left;
        font-weight: 700;
        font-size: 32px;
        line-height: 48px;
        color: #232323;
      }
      .protocolDesc {
        text-align: left;
      }
      .protocolItem {
        border-radius: 18px;
        padding: 20px;
        width: 100%;
      }
      .protocolContent {
        margin-top: 24px;
        flex-direction: column;
        gap: 20px;
        align-items: center;
      }
      .protocolText {
        font-weight: 600;
        font-size: 20px;
        line-height: 32px;
        color: #232323;
      }
    }

    .kycTitle,
    .verificationTitle {
      font-weight: 700;
      font-size: 32px;
      line-height: 42px;
      display: flex;
      align-items: center;
      margin: 0 auto 30px;
      padding-top: 50px;
      text-align: center;
      justify-content: center;
      color: #232323;
    }

    .kycList,
    .verificationList {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
      gap: 10px;
      padding-bottom: 60px;
      .verificationItem,
      .kycItem {
        width: 108px;
      }
    }

    .verificationList {
      margin-left: 14px;
      margin-right: 14px;
    }

    .verificationListsWrap {
      width: calc(100% + 28px);
      margin-left: -14px;
      margin-right: -14px;
    }
  }
  .japanDialog {
    width: 100%;
  }

  .japanInnerDialog {
    padding: 20px;
    width: 100%;
    height: 100%;
    border-radius: 12px;
    position: relative;
    text-align: center;
    .img {
      width: 80px;
      height: 80px;
      margin: 0 auto;
    }
    .text {
      margin-top: 30px;
      font-weight: 500;
      font-size: 16px;
      line-height: 28px;
      text-align: center;
      color: #d7538e;
    }
    .btn {
      cursor: pointer;
      margin: 40px auto 0;
      padding: 14px 30px;
      gap: 10px;
      display: inline-block;
      height: 52px;
      background: #d7538e;
      border-radius: 8px;
      font-weight: 500;
      font-size: 12px;
      line-height: 24px;
      text-align: center;
      color: #ffffff;
    }
    .close {
      position: absolute;
      right: 0;
      top: 0;
      width: 24px;
      height: 24px;
      cursor: pointer;
    }
  }

  .estoniaTitle {
    font-weight: 500;
    font-size: 18px;
    line-height: 30px;
    color: #232323;
    text-align: center;
  }

  .estoniaText1 {
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    text-align: center;
    color: #7a7a7a;
    margin-top: 10px;
  }

  .estoniaText2 {
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    text-align: center;
    color: #7a7a7a;
    margin-top: 20px;
  }

  .cookiePolicyPaddingBottom {
    padding-bottom: 382px;
  }

  .lawTitle {
    color: #232323;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
  }

  .lawTips {
    list-style: disc;
    color: #7a7a7a;
    margin-top: 10px;
    font-size: 12px;
    line-height: 20px;
    padding-left: 10px;
    padding-right: 10px;
  }

  .mobileCard {
    display: block;
    margin-bottom: 30px;
    img {
      width: 100%;
    }
  }

  .Frame1071 {
    padding: 20px 14px;
    .Frame1070 {
      margin-top: 28px;
      gap: 20px;
    }
    .Frame10701 {
      flex-direction: column;
    }

    .Rectangle852 {
      width: 100%;

      flex-shrink: 0;
    }

    .Rectangle8528 {
      width: 100%;
    }

    .Frame10662 {
      margin-top: 10px;
    }

    .Frame836 {
      margin-top: 10px;
    }

    .MaskGroup,
    .MaskGroup1 {
      display: none;
    }
    .MaskGroup2 {
      display: block;
      margin-top: 28px;
    }
  }

  .Frame867 {
    flex-direction: column;
    .Frame8671 {
      color: #232323;
      font-size: 26px;
      font-style: normal;
      font-weight: 600;
      line-height: 32px; /* 123.077% */
    }
  }

  .xMLParser {
    padding: 14px;
    margin-top: 0;
    margin-bottom: 0;

    .jSONParser {
      gap: 20px;
    }

    .sliderController,
    .chartCreator,
    .chartCreator1,
    .chartCreator2 {
      padding: 14px;
      border-radius: 12px;
    }
    .sliderController {
      gap: 20px;
      flex-direction: column;
    }

    .dataAggregator {
      border-radius: 12px;
    }

    .labelMaker {
      gap: 20px;
    }

    .readyToSeizeTheDigitalAdvParent {
      gap: 20px;
    }

    .button4 {
      font-size: 14px;
    }

    .readyToSeize {
      font-size: 24px;
      line-height: 28px;
    }

    .tabulator {
      gap: 20px;
    }

    .voyagerPass {
      font-size: 26px;
    }

    .formBuilder {
      flex-direction: column;
      > button {
        width: 100%;
      }
    }
  }

  // 活动
  // .activityBanner {
  //   position: fixed;
  //   top: 50px;
  //   z-index: 2;
  //   display: flex;
  //   width: 100%;
  //   padding: 14px;
  //   justify-content: space-between;
  //   align-items: center;
  //   flex-shrink: 0;
  //   background: linear-gradient(0deg, rgba(255, 198, 50, 0.42) 0%, rgba(255, 198, 50, 0.42) 100%), #fff;
  //   .innerActivityBanner {
  //     font-size: 12px;
  //     max-width: 1440px;
  //     display: flex;
  //     align-items: center;
  //     justify-content: flex-start;
  //     gap: 10px;
  //     margin: 0 auto;
  //     > button {
  //       margin-left: 10px;
  //     }
  //     svg {
  //       flex-shrink: 0;
  //     }
  //   }
  // }
}
