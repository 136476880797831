@import './index-tablet.module.scss';
@import './index-mobile.module.scss';

.main {
  max-width: 1440px;
  padding-left: 100px;
  padding-right: 100px;
  margin: 0 auto;
}

.privilege {
  display: flex;
  justify-content: space-between;
  margin: 40px auto 0;
  gap: 60px;
  .privilegeImage {
    max-width: 490px;
    width: 100%;
    flex-shrink: 0;
  }
  .privilegeContent {
    max-width: 560px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    .privilegeTitle {
      font-weight: 600;
      font-size: 32px;
      line-height: 44px;
      color: #232323;
    }
    .privilegeDesc {
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: #7a7a7a;
      margin-top: 12px;
    }
    .privilegeTip {
      color: #a5a5a5;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 18px; /* 150% */
      margin-top: 30px;
    }
  }
}

.mintNotice {
  margin: 80px auto 0;
  padding: 36px 50px;
  gap: 24px;
  background: #ffffff;
  border: 1px solid #f1f2f2;
  box-shadow: 0px 14px 42px rgba(126, 86, 86, 0.04);
  border-radius: 36px;

  .mintNoticeTitle {
    font-weight: 700;
    font-size: 34px;
    line-height: 52px;
    color: #232323;
  }
  .mintNoticeContent {
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;
    color: #717c7a;
    margin-top: 24px;
  }
}

.faqsHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 80px;
  margin-bottom: 30px;
  h1 {
    color: #232323;
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: 44px; /* 137.5% */
  }
  .tos {
    color: #0355bf;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 150% */
    display: flex;
    align-items: center;
    gap: 10px;
    cursor: pointer;
  }
}

.accordion {
  &::before {
    opacity: 0 !important;
  }
  border-top: 1px solid #f1f2f2;
  &:last-child {
    border-bottom: 1px solid #f1f2f2;
  }
  // :global {
  //   .Mui-expanded {
  //     margin-bottom: 0;
  //   }
  // }
}

.faqsList {
  margin-bottom: 40px;
  a {
    text-decoration: underline;
  }
}

.faqTitleWrap {
  margin: 10px auto;
  min-height: 48px !important;
  padding: 0;
  :global {
    .MuiAccordionSummary-content {
      margin: 0;
    }
  }
}

.faqContentWrap {
  padding: 0;
}

.faqTitle {
  color: #232323;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 150% */
}

.faqContent {
  color: #4f4f4f;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 157.143% */
}

/* 小于1024px tablet */
@media screen and (max-width: 1024px) {
  @include tablet();
}
/* 小于480px mobile */
@media screen and (max-width: 480px) {
  @include mobile();
}
