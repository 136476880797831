@import './index-tablet.module.scss';
@import './index-mobile.module.scss';

.redeemBgWrap {
  background: linear-gradient(0deg, rgba(3, 85, 191, 0.06) 0%, rgba(3, 85, 191, 0.06) 100%), #fff;
  min-height: calc(100vh - 492px);

  overflow: hidden;
}

.redeemBg {
  max-width: 1440px;
  margin: 0 auto;
  padding: 100px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 40px;
  h1 {
    color: #232323;
    font-size: 48px;
    font-style: normal;
    font-weight: 600;
    line-height: 56px; /* 116.667% */
  }
  .tip {
    color: #7a7a7a;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
    margin-top: 18px;
  }
  .redeemLeft {
    max-width: 500px;
  }
}

.nftAddressBox {
  display: flex;
  align-items: center;
  padding: 10px 10px 10px 20px;
  width: 100%;
  background: #ffffff;
  border: 1px solid #f1f2f2;
  border-radius: 12px;
  position: relative;
  margin-top: 60px;
  position: relative;
}

.input {
  padding-left: 0px !important;
  margin-left: 12px;
  border: 0;
  color: #7a7a7a;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 157.143% */
  width: 200px;
  border: 0;
  outline: none;
  &:focus {
    border-color: #f1f2f2 !important;
  }
}

.checkButton {
  padding: 4px 20px !important;
  margin-left: auto !important;
  max-width: none !important;
  flex-shrink: 0;
}

.contract {
  color: #a5a5a5;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 157.143% */
  margin-top: 14px;
}

.dataRedeemed {
  display: flex;
  flex-direction: column;
  border-radius: 14px;
  border: #f1f2f2;
  background: #fff;
  width: 262px;
  box-shadow: 0px 10px 24px 0px rgba(0, 0, 0, 0.02);
  backdrop-filter: blur(15px);
  > img {
    width: 262px;
    height: 262px;
  }
  .dataRedeemedRight {
    padding: 16px 20px;
    color: #232323;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px; /* 155.556% */
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    .buttonGroup {
      display: flex;
      align-items: center;
    }

    button {
      margin-left: 20px;
    }

    .dataRedeemedRightHeader {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
    }

    .vpCode {
      margin-top: 12px;
    }

    .checkResult {
      margin-top: 16px;
      svg {
        flex-shrink: 0;
      }
    }

    .status0 {
      border-radius: 20px;
      border: 1px solid #d92d2d;
      background: linear-gradient(0deg, rgba(217, 45, 45, 0.06) 0%, rgba(217, 45, 45, 0.06) 100%), #fff;
      display: flex;
      padding: 8px 12px;
      justify-content: center;
      align-items: center;
      gap: 8px;

      color: #232323;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px; /* 150% */
    }

    .status1 {
      border-radius: 20px;
      border: 1px solid #66cbad;
      background: linear-gradient(0deg, rgba(102, 203, 173, 0.1) 0%, rgba(102, 203, 173, 0.1) 100%), #fff;
      display: flex;
      padding: 8px 12px;
      justify-content: center;
      align-items: center;
      gap: 8px;
      color: #232323;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px; /* 150% */
    }
    .status2 {
      border-radius: 20px;
      border: 1px solid #f1f2f2;
      background: #fafafa;
      display: flex;
      padding: 8px 12px;
      justify-content: center;
      align-items: center;
      gap: 8px;
      color: #232323;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px; /* 150% */
    }
  }
}

.resultBox {
  min-height: 408px;
  width: 582px;
  justify-content: center;
  display: flex;
  align-items: center;
}

/* 小于1024px tablet */
@media screen and (max-width: 1024px) {
  @include tablet();
}
/* 小于480px mobile */
@media screen and (max-width: 480px) {
  @include mobile();
}
