@import './index-tablet.module.scss';
@import './index-mobile.module.scss';

.page {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
}

.img {
  width: 580px;
  position: relative;
  overflow: hidden;
}

.text {
  font-style: normal;
  font-weight: 600;
  font-size: 36px;
  line-height: 54px;
  color: #232323;
  margin-top: 20px;
  text-align: center;
}

@media screen and (max-width: 1024px) {
  @include tablet();
}

@media screen and (max-width: 480px) {
  @include mobile();
}
