@import './index-tablet.module.scss';
@import './index-mobile.module.scss';

.termsDialog {
  padding-left: 0;
  padding-right: 0;
  margin-bottom: 16px;
  font-weight: 400;
  font-size: 14px;
  line-height: 26px;
  color: #41514e;
  width: 100%;

  .title {
    text-align: left;
    font-weight: 700;
    font-size: 36px;
    line-height: 54px;
    color: #232323;
  }

  .subTitle {
    font-weight: 400;
    font-size: 14px;
    line-height: 1;
    color: #4f4f4f;
    margin-top: 14px;
    margin-bottom: 50px;
    > span {
      border-left: 1px solid #a5a5a5;
      display: inline-block;
      margin-left: 10px;
      padding-left: 10px;
      cursor: pointer;
    }
  }

  p {
    margin-bottom: 16px;
    font-weight: 400;
    font-size: 14px;
    line-height: 28px;
    color: #41514e;
  }

  .paragraphTitle {
    font-weight: 500;
    font-size: 22px;
    line-height: 34px;
    color: #0355bf;
    margin-top: 50px;
  }

  ul {
    margin-left: 30px;
  }

  li {
    list-style-type: disc;
    margin-bottom: 10px;
    list-style-type: disc;
    margin-bottom: 10px;
    font-weight: 400;
    font-size: 14px;
    line-height: 28px;
    color: #41514e;
  }

  a {
    color: #0355bf;
  }
}

/* 小于1024px tablet */
@media screen and (max-width: 1024px) {
  @include tablet();
}
/* 小于480px mobile */
@media screen and (max-width: 480px) {
  @include mobile();
}
