@mixin mobile {
  .box {
    width: 320px;
    padding: 0 20px;
    .btnBox {
      margin-top: 24px;
      gap: 12px;
      flex-direction: column;
    }
    .btn1,
    .btn2 {
      min-width: 100px;
    }
    .desc {
      font-size: 12px;
      line-height: 18px;
    }
  }

  .minWidthBox {
    min-width: auto;
  }
}
