@mixin mobile {
  .header {
    display: none;
  }
  .headerWrap {
    right: 14px;
  }
  .header3 {
    display: flex;
    height: 50px;
    align-items: center;
    justify-content: flex-end;
  }
}
