@import './index-tablet.module.scss';
@import './index-mobile.module.scss';

.button {
  cursor: pointer;
  background: #f1f2f2;
  border-radius: 10px;
  border: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 30px;
  height: 52px;
  color: #d0d3d3;
  font-weight: 500;
  font-size: 16px;
  margin: 0;
  position: relative;
  border: 1px solid #f1f2f2;
  -webkit-appearance: none;
  outline: none;
  white-space: nowrap;
  &:active {
    outline: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }
}

.linkBtn {
  border: 0;
  text-decoration: underline;
  cursor: pointer;
  padding: 0;
  height: auto;
  background: #fff !important;
  &:hover {
    background: #fff !important;
  }
}

.enabled {
  background: #0355bf;
  border-color: #0355bf;
  color: #fff;
  &:hover {
    background: #023273;
    border-color: #023273;
  }
}

.ghost {
  background: #ffffff;
  border-color: #a0a8a7;
  color: #232323;
  &:hover {
    background: #ececec;
    border-color: #a0a8a7;
  }
}

.redGhost {
  background: #ec5962;
  border-color: #ec5962;
  color: #fff;
  &:hover {
    background: #d5394a;
    border-color: #d5394a;
  }
}

.small {
  font-size: 14px;
  line-height: 22px;
  padding: 8px 20px;
  height: 38px;
}

.middle {
  font-size: 14px;
  line-height: 22px;
  padding: 8px 20px;
  height: 44px;
}

.iconBtn {
  background: #ffffff;
  padding: 8px 12px;
  &:hover {
    background: #ececec;
  }
}

/* 小于1024px tablet */
@media screen and (max-width: 1024px) {
  @include tablet();
}

@media screen and (max-width: 480px) {
  @include mobile();
}
